import { CEFRCode } from "utils/cefr";
import { LanguageCode } from "utils/languages";

export const DEBUG = process.env.NODE_ENV === "development" && !process.env.REACT_APP_PROD;

export const APP_URL = DEBUG ? "http://127.0.0.1:3000" : "https://pimaru.com";

export const languages: LanguageCode[] = ["en", "de", "pt", "fr", "es", "it"];
export const instructions: LanguageCode[] = ["en", "de", "pt", "fr", "es", "it"];
export const cefrLevels: CEFRCode[] = ["a1", "a2", "b1", "b2", "c1"];
