import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";

type MultiStepFormContextType = {
  currentStep: number;
  decrementStep: () => void;
  incrementStep: () => void;
  setStep: (step: number) => void;
};

const MultiStepFormContext = createContext<MultiStepFormContextType>({
  currentStep: 0,
  decrementStep: () => {},
  incrementStep: () => {},
  setStep: () => {},
});

type MultiStepFormProps = {
  children: ((value: MultiStepFormContextType) => React.ReactNode) | React.ReactNode;
  onSubmit?: (data: any) => void;
  steps: number;
  reset?: number;
};

const MultiStepForm: React.FC<MultiStepFormProps> = function MultiStepFormComponent({
  children,
  onSubmit,
  steps,
  reset = 0,
}) {
  const [currentStep, setCurrentStep] = useState(0);

  const setStep = useCallback((step: number) => setCurrentStep(step), [setCurrentStep]);
  const incrementStep = useCallback(() => setCurrentStep((step) => step + 1), [setCurrentStep]);
  const decrementStep = useCallback(() => setCurrentStep((step) => step - 1), [setCurrentStep]);

  useEffect(() => {
    if (currentStep !== steps) return;
    if (onSubmit) onSubmit({});
  }, [currentStep]);

  useEffect(() => {
    setStep(0);
  }, [reset]);

  const value = { currentStep: Math.min(currentStep, steps - 1), setStep, incrementStep, decrementStep };

  return (
    <MultiStepFormContext.Provider value={value}>
      {typeof children === "function" ? children(value) : children}
    </MultiStepFormContext.Provider>
  );
};

type MultiStepItemProps = {
  children: React.ReactNode;
  step: number | number[] | undefined;
};

const MultiStepItem: React.FC<MultiStepItemProps> = function MultiStepItemComponent({ children, step }) {
  const { currentStep } = React.useContext(MultiStepFormContext);

  const show = useMemo(() => {
    if (step === undefined) return true;

    return Array.isArray(step) ? step.includes(currentStep) : step === currentStep;
  }, [currentStep, step]);

  return show && children;
};

export default MultiStepForm;
export { MultiStepItem };
