import { useTranslation } from "react-i18next";

import { Button } from "components/form";
import Popup, { PopupProps } from "./Popup";

export interface ConfirmPopupProps extends Omit<PopupProps, "buttons"> {
  onConfirm: () => void;
}

const ConfirmPopup = function ConfirmPopupComponent({ children, icon, onCancel, onConfirm }: ConfirmPopupProps) {
  const { t } = useTranslation();

  return (
    <Popup
      buttons={
        <>
          <Button className="grow" onClick={onCancel}>
            {t("common.no")}
          </Button>
          <Button
            className="grow bg-white text-slate-800 hover:text-slate-900 hover:bg-slate-100 border border-slate-800"
            onClick={onConfirm}
          >
            {t("common.yes")}
          </Button>
        </>
      }
      icon={icon}
      onCancel={onCancel}
    >
      {children}
    </Popup>
  );
};

export default ConfirmPopup;
