import makeRequest from "services/api/makeRequest";
import { ServiceType } from "services/api/types";

type InitEmailVerificationData = { email: string; type: "existing" | "new" };
type InitEmailVerification = ServiceType<InitEmailVerificationData, any>;

export const initEmailVerification: InitEmailVerification = async function initEmailVerificationService(c, data) {
  const options = { data, method: "POST" };

  const response = await makeRequest(c, `/email/verify`, options);

  return response;
};

type CompleteEmailVerificationData = { code: string; email: string; verificationID: string };
type CompleteEmailVerification = ServiceType<CompleteEmailVerificationData, any>;

export const completeEmailVerification: CompleteEmailVerification = async function completeEmailVerificationService(
  c,
  { verificationID, ...data }
) {
  const options = { data, method: "POST" };

  const response = await makeRequest(c, `/email/verify/${verificationID}`, options);

  return response;
};
