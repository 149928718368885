/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo, useState } from "react";
import validator from "validator";

import { Button, FormErrors, TextInput, useForm, Validations } from "components/form";
import Navigation from "components/UserInterface/Navigation";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useAPI } from "services/api";

const SignInForm = function SignInFormComponent({ onSignUp }: { onSignUp?: () => void }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const fields = useMemo(() => ({ email, password }), [email, password]);
  const validations = [
    [["email"], validator.isEmail, t("common.error.emailInvalid")],
    [["password"], (v: any) => v.length >= 8, t("common.error.passwordTooShort")],
  ] as Validations;

  const { services } = useAPI();

  const { formErrors, hookSubmit, submitting } = useForm({ fields, validations });

  const handleSubmit = hookSubmit(
    useCallback(async () => {
      await services.signIn(fields);

      navigate("/");
    }, [fields, navigate, services])
  );

  return (
    <form className="flex flex-col flex-grow gap-8" onSubmit={handleSubmit}>
      <h2 className="text-2xl font-bold">{t("signin.title")}</h2>
      <div className="flex flex-col gap-4">
        <TextInput
          autoComplete="email"
          autofocus
          errors={formErrors.getErrors("email")}
          label={t("signin.label.email")}
          type="email"
          value={email}
          onChange={setEmail}
        />
        <div className="flex flex-col gap-3">
          <TextInput
            autoComplete="current-password"
            errors={formErrors.getErrors("password")}
            label={t("signin.label.password")}
            type="password"
            value={password}
            onChange={setPassword}
          />
          <Link className="text-blue-600 p-0 m-0 hover:underline text-sm" to="/reset-password">
            {t("signin.forgotPassword")}
          </Link>
        </div>
      </div>
      <Button type="submit" disabled={!formErrors.validate()} loading={submitting}>
        {t("signin.submit")}
      </Button>
      <FormErrors context={formErrors} exclude={["email", "password"]} />
      <a className="inline self-center text-blue-600 p-0 m-0 hover:underline cursor-pointer" onClick={onSignUp}>
        {t("signin.signUpInstead")}
      </a>
    </form>
  );
};

const SignIn = function SignInPageComponent() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-grow flex-col">
      <Navigation />
      <div className="flex flex-col items-center">
        <div className="flex flex-col flex-grow w-full max-w-md gap-4 p-4">
          <SignInForm onSignUp={() => navigate("/signup")} />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
