import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";

const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(({ onBlur, onFocus, ...props }, ref_) => {
  const [focused, setFocused] = useState<boolean>(false);

  const ref = useRef<HTMLInputElement | null>(null);
  useImperativeHandle(ref_, () => ref.current as HTMLInputElement);

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      if (onFocus) onFocus(e);
    },
    [onFocus, setFocused]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      if (onBlur) onBlur(e);
    },
    [onBlur, setFocused]
  );

  const handleClear = useCallback(() => {
    if (!ref.current) return;

    const event = new Event("input", { bubbles: true });
    nativeInputValueSetter?.call(ref.current, "");
    ref.current.dispatchEvent(event);

    ref.current.focus();
  }, [ref]);

  return (
    <div className="relative flex items-center gap-4 px-2 border-2 border-slate-300 focus-within:border-blue-500 rounded-md">
      <input
        ref={ref}
        type="text"
        className="w-full p-3 pl-10 outline-none"
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      />
      <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
        <MagnifyingGlassIcon className="w-5 h-5 text-slate-400 stroke-[2.5]" />
      </span>
      {(focused || ref.current?.value) && (
        <div className="cursor-pointer text-slate-500" onClick={handleClear}>
          <XMarkIcon className="w-5 h-5 stroke-[2.5]" />
        </div>
      )}
    </div>
  );
});

export default SearchInput;
