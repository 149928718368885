import React, { useEffect, useState } from "react";
import { CEFRCode } from "utils/cefr";
import { LanguageCode } from "utils/languages";

type CourseConfigurationData = { instruction?: LanguageCode; language?: LanguageCode; level?: CEFRCode };

type CourseContextType = {
  clearCourse: () => void;
  instruction?: LanguageCode;
  language?: LanguageCode;
  level?: CEFRCode;
  setupComplete: boolean;
  setupCourse: (data: CourseConfigurationData) => void;
  preconfigureCourse: (data: CourseConfigurationData) => void;
};

const CourseContext = React.createContext<CourseContextType | Record<string, never>>({});

const CourseContextProvider = function CourseContextProvider({ children }: { children: React.ReactNode }) {
  const [language, setLanguage] = useState<LanguageCode>();
  const [level, setLevel] = useState<CEFRCode>();
  const [instruction, setInstruction] = useState<LanguageCode>("en");
  const [setupComplete, setSetupComplete] = useState<boolean>(false);

  useEffect(() => {
    const data = localStorage.getItem("courseSetup");
    setSetupComplete(false);

    if (!data) return;

    let parsedData;
    try {
      parsedData = JSON.parse(data);
    } catch (e) {
      return;
    }

    setInstruction(parsedData?.instruction);
    setLanguage(parsedData?.language);
    setLevel(parsedData?.level);
    if (parsedData?.instruction && parsedData?.language && parsedData?.level) {
      setSetupComplete(true);
    }
  }, []);

  const setupCourse = (data: CourseConfigurationData) => {
    if (!data.instruction || !data.language || !data.level) return;

    setInstruction(data.instruction);
    setLanguage(data.language);
    setLevel(data.level);
    localStorage.setItem("courseSetup", JSON.stringify(data));
    setSetupComplete(true);
  };

  const preconfigureCourse = (data: CourseConfigurationData) => {
    if (data.instruction) setInstruction(data.instruction);
    if (data.language) setLanguage(data.language);
    if (data.level) setLevel(data.level);
  };

  const clearCourse = () => {
    setInstruction("en");
    setLanguage(undefined);
    setLevel(undefined);
    setSetupComplete(false);

    localStorage.removeItem("courseSetup");
  };

  const context = { clearCourse, instruction, setupComplete, language, level, setupCourse, preconfigureCourse };

  return <CourseContext.Provider value={context}>{children}</CourseContext.Provider>;
};

const useCourseContext = () => React.useContext(CourseContext);

export default CourseContext;
export { CourseContextProvider, useCourseContext };
