import React, { useCallback, useEffect, useState } from "react";

interface SpeechSynthContextType {
  activeID?: string | null;
  onComplete?: (_: { speechSynthID: string }) => void;
  setActiveID?: (_: string | null) => void;
}

export const SpeechSynthContext = React.createContext<SpeechSynthContextType>({ activeID: null });

interface SpeechSynthProviderProps extends SpeechSynthContextType {
  children: React.ReactNode;
}

const SpeechSynthProvider = function SpeechSynthProviderComponent({
  activeID = null,
  children,
  onComplete,
}: SpeechSynthProviderProps) {
  const [active, setActive] = useState(activeID);

  useEffect(() => {
    setActive(activeID);
  }, [activeID]);

  const context = { activeID: active, onComplete, setActiveID: setActive };

  return <SpeechSynthContext.Provider value={context}>{children}</SpeechSynthContext.Provider>;
};

type SpeechSynthHookProps = {
  speechSynthID?: string;
  trigger: () => void;
  interrupt: () => void;
};

const useSpeechSynth = function useSpeechSynth({ speechSynthID, trigger, interrupt }: SpeechSynthHookProps) {
  const [speaking, setSpeaking] = useState(false);

  const { activeID, onComplete } = React.useContext(SpeechSynthContext);

  const onSpeechSynthComplete = useCallback(() => {
    if (!speaking) return;

    setSpeaking(false);

    if (!onComplete || !speechSynthID) return;

    onComplete({ speechSynthID });
  }, [onComplete, speaking, speechSynthID]);

  useEffect(() => {
    if (activeID !== speechSynthID) return () => {};

    setSpeaking(true);

    return () => setSpeaking(false);
  }, [activeID]);

  useEffect(() => {
    if (!speaking) return () => {};

    trigger();

    return interrupt;
  }, [speaking]);

  return { onSpeechSynthComplete, activeID };
};

export type SpeechSynthProps = { language?: string; speakerID?: string; speechSynthID?: string };

export default useSpeechSynth;
export { SpeechSynthProvider };
