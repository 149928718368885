import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Navigation from "components/UserInterface/Navigation";
import { useAPI } from "services/api";
import UserProfile from "./UserProfile";

const Profile = function ProfilePageComponent() {
  const [loaded, setLoaded] = useState(false);

  const { userID } = useParams();
  const { user } = useAPI();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userID) navigate(`/profile/${user?.id}`, { replace: true });
    else if (userID === user?.id && !user?.email) navigate("/signup", { replace: true });
    else setLoaded(true);

    return () => setLoaded(false);
  }, [user, userID]);

  return (
    <div className="flex flex-col flex-grow items-stretch">
      <Navigation />
      {userID && loaded && <UserProfile userID={userID} />}
    </div>
  );
};

export default Profile;
