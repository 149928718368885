import makeRequest from "services/api/makeRequest";
import { ServiceType, VocabularyCard } from "services/api/types";

export type ListVocabularyCardsData = {
  instruction: string;
  language: string;
  limit: number;
  offset: number;
  query?: string;
};
export type ListVocabularyCardsResult = { count: number; vocabularyCards: VocabularyCard[] };
export type ListVocabularyCards = ServiceType<ListVocabularyCardsData, ListVocabularyCardsResult>;

export const listVocabularyCards: ListVocabularyCards = async function listVocabularyCardsService(c, params) {
  const options = { params, method: "GET" };

  return (await makeRequest(c, `/vocabulary`, options)) as ListVocabularyCardsResult;
};

export type QueryVocabularyCardData = { vocabularyCardID: string };
export type QueryVocabularyCardResult = VocabularyCard;
export type QueryVocabularyCard = ServiceType<QueryVocabularyCardData, QueryVocabularyCardResult>;

export const queryVocabularyCard: QueryVocabularyCard = async function queryVocabularyCardService(
  c,
  { vocabularyCardID }
) {
  const options = { method: "GET" };

  return (await makeRequest(c, `/vocabulary/${vocabularyCardID}`, options)) as QueryVocabularyCardResult;
};

export type CreateVocabularyCardData = { instruction: string; language: string; text: string; translation: string };
export type CreateVocabularyCardResult = VocabularyCard;
export type CreateVocabularyCard = ServiceType<CreateVocabularyCardData, CreateVocabularyCardResult>;

export const createVocabularyCard: CreateVocabularyCard = async function createVocabularyCardService(c, data) {
  const options = { data, method: "POST" };

  return (await makeRequest(c, `/vocabulary`, options)) as CreateVocabularyCardResult;
};

export type ScheduleVocabularyCardData = { vocabularyCardID: string; rating: "again" | "hard" | "good" | "easy" };
export type ScheduleVocabularyCardResult = VocabularyCard;
export type ScheduleVocabularyCard = ServiceType<ScheduleVocabularyCardData, ScheduleVocabularyCardResult>;

export const scheduleVocabularyCard: ScheduleVocabularyCard = async function scheduleVocabularyCardService(
  c,
  { vocabularyCardID, ...data }
) {
  const options = { data, method: "POST" };

  return (await makeRequest(c, `/vocabulary/${vocabularyCardID}/schedule`, options)) as ScheduleVocabularyCardResult;
};

export type UpdateVocabularyCardData = { vocabularyCardID: string; rating: "again" | "hard" | "good" | "easy" };
export type UpdateVocabularyCardResult = VocabularyCard;
export type UpdateVocabularyCard = ServiceType<UpdateVocabularyCardData, UpdateVocabularyCardResult>;

export const updateVocabularyCard: UpdateVocabularyCard = async function updateVocabularyCardService(
  c,
  { vocabularyCardID, ...data }
) {
  const options = { data, method: "POST" };

  return (await makeRequest(c, `/vocabulary/${vocabularyCardID}`, options)) as UpdateVocabularyCardResult;
};

export type DeleteVocabularyCardData = { vocabularyCardID: string };
export type DeleteVocabularyCardResult = VocabularyCard;
export type DeleteVocabularyCard = ServiceType<DeleteVocabularyCardData, DeleteVocabularyCardResult>;

export const deleteVocabularyCard: DeleteVocabularyCard = async function deleteVocabularyCardService(
  c,
  { vocabularyCardID }
) {
  const options = { method: "DELETE" };

  return (await makeRequest(c, `/vocabulary/${vocabularyCardID}`, options)) as DeleteVocabularyCardResult;
};
