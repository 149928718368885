import React from "react";

import Navigation from "./UserInterface/Navigation";
import i18n from "i18next";

class ErrorBoundary extends React.Component {
  state: { hasError: boolean };

  props: any;

  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: any, errorInfo: any) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col grow">
          <Navigation />
          <div className="flex flex-col grow justify-center self-center max-w-md">
            <h1 className="text-center text-xl">{i18n.t("error.generic")}</h1>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
