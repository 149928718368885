import { ChevronDoubleDownIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import { ContainerProvider } from "utils/container";
import twm from "utils/twm";

export type ScrollChatProps = { children: React.ReactNode; className?: string };

const ScrollChat = function ScrollChatComponent({ children, className }: ScrollChatProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isAtBottom, setIsAtBottom] = useState(true);

  useEffect(() => {
    if (!isAtBottom || !containerRef.current) return () => {};

    const container = containerRef.current;

    const mutationObserver = new MutationObserver(() => {
      container.scrollTop = container.scrollHeight;
    });
    mutationObserver.observe(container, { characterData: true, childList: true, subtree: true });

    return () => mutationObserver.disconnect();
  }, [containerRef, isAtBottom]);

  const handleScroll = () => {
    // Detect if the user manually scrolled up and disable auto-scrolling if so
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    setIsAtBottom(scrollHeight - clientHeight <= scrollTop);
  };

  const handleScrollBottom = () => {
    if (!containerRef.current) return;

    containerRef.current.scrollTop = containerRef.current.scrollHeight;
    setIsAtBottom(true);
  };

  const handleScrollTop = () => {
    if (!containerRef.current) return;

    containerRef.current.scrollTop = 0;
    handleScroll();
  };

  useEffect(() => {
    if (!containerRef.current) return;

    handleScrollTop();
  }, [containerRef]);

  return (
    <div className={twm("relative flex flex-col flex-grow overflow-hidden", className)}>
      <div
        className="flex flex-col flex-grow h-0 overflow-y-scroll mt-0.5 px-2 sm:px-6 gap-6 no-scrollbar"
        ref={containerRef}
        onScroll={handleScroll}
      >
        <ContainerProvider containerRef={containerRef}>{children}</ContainerProvider>
      </div>
      <div
        className={twm(
          "absolute left-5 bottom-2.5 bg-white p-2 shadow rounded-full text-slate-500 hover:cursor-pointer hover:text-black hover:shadow-lg",
          {
            hidden: isAtBottom,
          }
        )}
      >
        <ChevronDoubleDownIcon className="w-4 h-4" onClick={handleScrollBottom} />
      </div>
    </div>
  );
};

export default ScrollChat;
