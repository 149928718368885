import { ChevronUpIcon, ChevronDownIcon, CheckCircleIcon, StarIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { useAPI } from "services/api";
import { QueryLessonStatsResult } from "services/api/routes";
import { useLesson } from "../LessonContext";
import useErrorHandler from "utils/useErrorHandler";
import { toast } from "react-toastify";
import classNames from "classnames";

type StarRatingInputProps = {
  className?: string;
  max: number;
  onChange: (rating: number) => void;
  starClassName?: string;
  value: number;
};

const StarRatingInput: React.FC<StarRatingInputProps> = ({ className, max, onChange, starClassName, value }) => {
  const currentRating = value;
  const [isMouseDown, setIsMouseDown] = useState(false);

  const updateRating = (index: number, isHalf: boolean) => {
    const newRating = index * 2 + (isHalf ? 1 : 2);

    if (onChange) onChange(newRating);
  };

  const handleMouseMove = (index: number, event: React.MouseEvent) => {
    if (isMouseDown) {
      const rect = event.currentTarget.getBoundingClientRect();
      const isHalf = event.clientX - rect.left < rect.width / 2;
      updateRating(index, isHalf);
    }
  };

  const handleMouseDown = () => {
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const clampedRating = useMemo(() => Math.max(0, Math.min(max, currentRating)), [max, currentRating]);

  const fullStars = useMemo(() => Math.floor(clampedRating / 2), [clampedRating]);
  const halfStar = useMemo(() => clampedRating % 2 === 1, [clampedRating]);
  const emptyStars = useMemo(() => max / 2 - fullStars - (halfStar ? 1 : 0), [halfStar, max, fullStars]);

  return (
    <div className={twMerge("flex gap-4 cursor-pointer", className)} onMouseUp={handleMouseUp}>
      {[...Array(fullStars + (halfStar ? 1 : 0) + emptyStars)].map((_, i) => (
        <div
          key={`star-${i}`}
          className={twMerge("relative w-12 h-12", starClassName)}
          onClick={(e) => {
            const rect = e.currentTarget.getBoundingClientRect();
            const isHalf = e.clientX - rect.left < rect.width / 2;
            updateRating(i, isHalf);
          }}
          onMouseMove={(e) => handleMouseMove(i, e)}
          onMouseDown={handleMouseDown}
        >
          {i < fullStars && (
            <StarIcon className={twMerge("text-yellow-500 w-12 h-12 absolute top-0 left-0 ", starClassName)} />
          )}
          {i === fullStars && halfStar && (
            <>
              <StarIcon className={twMerge("text-gray-300 w-12 h-12 absolute top-0 left-0", starClassName)} />
              <StarIcon
                className={twMerge("text-yellow-500 w-12 h-12 absolute top-0 left-0 clip-left-half", starClassName)}
              />
            </>
          )}
          {i >= fullStars + (halfStar ? 1 : 0) && (
            <StarIcon className={twMerge("text-gray-300 w-12 h-12 absolute top-0 left-0", starClassName)} />
          )}
        </div>
      ))}
    </div>
  );
};

const LessonSummary = () => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [stats, setStats] = useState<QueryLessonStatsResult>();
  const [expandFeedback, setExpandFeedback] = useState(false);

  const handleError = useErrorHandler();
  const navigate = useNavigate();
  const { lesson } = useLesson();
  const { services, user } = useAPI();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      let result;
      try {
        result = await services.queryLessonStats({ lessonID: lesson.id });
      } catch (error) {
        handleError(null, "lesson.summary.error.queryStats");
        navigate("/");
        return;
      }

      const { exerciseCount, solvedCount } = result;

      if (solvedCount < exerciseCount) {
        handleError(null, "lesson.summary.error.notCompleted");
        navigate("/");
        return;
      }

      setStats(result);

      try {
        result = await services.queryLessonFeedback({ lessonID: lesson.id });
        setRating(result.rating);
        setFeedback(result.feedback);
      } catch (error) {
        /* empty */
      }
    })();
  }, [lesson.id]);

  const submitFeedback = useCallback(async () => {
    (async () => {
      try {
        await services.provideLessonFeedback({ lessonID: lesson.id, rating, text: feedback });
      } catch (error) {
        handleError(error, "lesson.summary.error.submitFeedback");
        return;
      }

      toast.success(t("lesson.summary.feedbackSubmitted"));
    })();
  }, [feedback, rating, setFeedback, setRating]);

  if (!stats) return null;

  return (
    <div className="flex flex-col grow items-stretch justify-between bg-white font-tutor">
      <div className="flex flex-col grow p-4 sm:p-8 gap-4">
        <div className="relative flex justify-center gap-4 mt-[2vh]">
          <h2 className="text-2xl">{t("lesson.summary.title", { lesson: lesson.title })}</h2>
        </div>
        <div className="flex grow flex-col justify-center items-center gap-4">
          <CheckCircleIcon className="w-36 h-36 text-green-500" />
          <Link to=".." state={{ startFromBeginning: true }} className="text-blue-500 hover:underline">
            {t("lesson.summary.startFromBeginning")}
          </Link>
        </div>
        <div className={classNames({ "mb-40": !expandFeedback }, "space-y-4")}>
          <Link
            to="/course"
            className="bg-blue-500 block hover:bg-blue-600 text-white transition-colors py-1 px-4 rounded-sm text-center leading-loose text-lg"
          >
            {t("lesson.summary.startNext")}
          </Link>
        </div>
      </div>
      {expandFeedback && (
        <div className="flex flex-col px-3 py-1 sm:px-8 sm:pb-8 bg-slate-200">
          <div className="flex items-center gap-2 cursor-pointer" onClick={() => setExpandFeedback(false)}>
            <ChevronDownIcon className="stroke-[2.5] inline w-4 h-4" />
            <span>{t("lesson.summary.feedback.hideSection")}</span>
          </div>
          <div className="flex flex-col gap-4">
            <span>{t("lesson.summary.feedback.give")}</span>
            <textarea
              className="w-full resize-none border border-slate-600 py-2 px-4 bg-slate-100 rounded-sm"
              maxLength={1000}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder={t("lesson.summary.feedbackPlaceholder")}
              rows={2}
              value={feedback}
            />
            <div className="flex flex-col gap-4 sm:flex-row justify-between">
              <span className="flex grow items-center gap-4 self-center">
                <span className="text-lg">{t("lesson.summary.feedbackRating")}:</span>
                <StarRatingInput
                  className="gap-2"
                  starClassName="w-8 h-8"
                  max={10}
                  onChange={setRating}
                  value={rating}
                />
              </span>
              <button
                className={classNames("border text-white py-1 px-4 rounded-sm self-stretch sm:self-end leading-loose", {
                  "bg-slate-400 curson-default": !feedback,
                  "bg-blue-500 hover:bg-blue-600 ": feedback,
                })}
                disabled={!feedback}
                onClick={submitFeedback}
              >
                {t("lesson.summary.submitFeedback")}
              </button>
            </div>
          </div>
        </div>
      )}
      {user?.email && !expandFeedback && (
        <div className="sm:px-8 gap-2 px-3 py-1 bg-gray-200">
          <div className="flex items-center gap-2 cursor-pointer" onClick={() => setExpandFeedback(true)}>
            <ChevronUpIcon className="stroke-[2.5] inline w-4 h-4" />
            <span>{t("lesson.summary.feedback.showSection")}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LessonSummary;
