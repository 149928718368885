import { Fragment } from "react";

import { FlagIcon, StarIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";

type ProgressBarProps = {
  checkpoints?: number[];
  progress: number;
};

/**
 * A component that displays a progress bar with checkpoints.
 */
const ProgressBar = function ProgressBarComponent({ checkpoints = [], progress }: ProgressBarProps) {
  return (
    <div className={"relative cursor-pointer"}>
      <div className="relative h-1.5 w-full flex items-center my-5 gap-3 px-3">
        {checkpoints.map((cp, index) => {
          const prevCP = index === 0 ? 0 : checkpoints[index - 1];
          const barGrow =
            (cp - prevCP) /
            Math.min(...[0, ...checkpoints].map((v, i, a) => (i === 0 ? Number.MAX_VALUE : v - a[i - 1])));
          const partialProgress = progress < cp && progress >= prevCP;
          const partialProgressWidthPCT = ((progress - prevCP) / (Number(cp) - prevCP)) * 100;

          return (
            <Fragment key={index}>
              <div
                style={{ flexGrow: barGrow }}
                className={classNames("relative h-1.5 rounded-full", {
                  "bg-green-500": progress >= Number(cp),
                  "bg-slate-200": progress < Number(cp),
                })}
              >
                {" "}
                {partialProgress && (
                  <div
                    style={{ width: `${partialProgressWidthPCT}%` }}
                    className="absolute left-0 inset-y-0 rounded-full bg-green-200"
                  />
                )}
              </div>
              <div
                className={classNames("flex items-start border-2  p-1 rounded-full leading-3", {
                  "border-green-500": progress >= Number(cp),
                  "border-slate-200": progress < Number(cp),
                })}
              >
                {index < checkpoints.length - 1 ? (
                  <FlagIcon
                    className={classNames("h-2.5 w-2.5 m-px ", {
                      "text-green-500": progress >= Number(cp),
                      "text-slate-400": progress < Number(cp),
                    })}
                  />
                ) : (
                  <StarIcon
                    className={classNames("h-3 w-3 ", {
                      "text-slate-400": progress < 99,
                      "text-green-600": progress >= 99,
                    })}
                  />
                )}
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressBar;
