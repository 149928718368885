import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import validator from "validator";

import { Button, TextInput, MultiStepForm, MultiStepItem, useForm, Validations, FormErrors } from "components/form";
import Navigation from "components/UserInterface/Navigation";
import { useAPI } from "services/api";

type cb = () => void;

type SignUpFormProps = { onComplete?: () => void; onSignIn?: () => void; referral?: string };

const SignUpForm: React.FC<SignUpFormProps> = function SignUpFormComponent({ onComplete, onSignIn }) {
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [emailVerifier, setEmailVerifier] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVerifier, setPasswordVerifier] = useState("");
  const [passwordVerifierSet, setPasswordVerifierSet] = useState(false);
  const [tos, setTOS] = useState(false);
  const [verificationID, setVerificationID] = useState("");

  useEffect(() => {
    if (passwordVerifier.length) setPasswordVerifierSet(password.length > 0);
  }, [passwordVerifier]);

  const { services } = useAPI();
  const { t } = useTranslation();

  const validations = [
    [["email"], validator.isEmail, t("common.error.emailInvalid")],
    [["code"], (v: string) => v.length === 6, t("common.error.codeInvalid")],
    [["name"], (v: string) => v.length >= 2, t("common.error.nameTooShort")],
    [["password"], (v: string) => v.length >= 8, t("common.error.passwordTooShort")],
    [
      ["password", "passwordVerifier"],
      (p: string, pv: string) => p === pv || (p.length > 0 && !passwordVerifierSet),
      t("common.error.passwordsDontMatch"),
    ],
  ] as Validations;

  const { formErrors, hookSubmit, submitting } = useForm({
    fields: { code, email, emailVerifier, name, password, passwordVerifier },
    validations,
  });

  const handleSubmitEmail = useCallback(
    async (success: cb) => {
      const response = await services.initEmailVerification({ email, type: "new" });
      setVerificationID(response.verificationID);

      success();
    },
    [email, services, setVerificationID],
  );

  const handleSubmitCode = useCallback(
    async (success: cb) => {
      const response = await services.completeEmailVerification({ code, email, verificationID });

      setEmailVerifier(response.emailVerifier);
      setCode("");
      success();
    },
    [code, email, services, setCode, setEmailVerifier, verificationID],
  );

  const handleSubmit = useCallback(async () => {
    if (!tos) throw new Error("TOS not accepted");

    await services.signUp({ email, emailVerifier, name, password });
    await services.reauth({ refreshToken: localStorage.getItem("refreshToken") });

    try {
      (window as any).fbq("track", "CompleteRegistration");
    } catch (e) {
      /* Ignore */
    }

    if (onComplete) onComplete();
  }, [email, emailVerifier, name, onComplete, password, services, tos]);

  return (
    <div className="flex flex-col flex-grow gap-8">
      <h2 className="text-2xl font-bold">{t("signup.title")}</h2>
      <MultiStepForm steps={3} onSubmit={() => {}}>
        {({ incrementStep, setStep }) => (
          <>
            <MultiStepItem step={0}>
              <form
                className="flex flex-col gap-8"
                onSubmit={hookSubmit(() => handleSubmitEmail(incrementStep), { fields: ["email"] })}
              >
                <div className="flex flex-col gap-4">
                  <TextInput
                    autoComplete="email"
                    autofocus
                    errors={formErrors.getErrors("email")}
                    label={t("signup.label.email")}
                    type="email"
                    value={email}
                    onChange={setEmail}
                  />
                </div>
                <div className="flex">
                  <Button
                    className="flex-1"
                    disabled={!formErrors.validate({ fields: ["email"] })}
                    loading={submitting}
                    type="submit"
                  >
                    {t("common.sendVerificationCode")}
                  </Button>
                </div>
                <FormErrors context={formErrors} exclude={["email"]} />
              </form>
              <a className="inline self-center text-blue-600 p-0 m-0 hover:underline cursor-pointer" onClick={onSignIn}>
                {t("signup.signInInstead")}
              </a>
            </MultiStepItem>
            <MultiStepItem step={1}>
              <form
                className="flex flex-col gap-8"
                onSubmit={hookSubmit(() => handleSubmitCode(incrementStep), { fields: ["code"] })}
              >
                <div className="flex flex-col gap-4">
                  <TextInput disabled label={t("signup.label.email")} type="email" value={email} />
                  <TextInput
                    autoComplete="one-time-code"
                    autofocus
                    errors={formErrors.getErrors("code")}
                    label={t("signup.label.code")}
                    type="text"
                    pattern="[0-9]*"
                    value={code}
                    onChange={setCode}
                  />
                  <div className="text-sm text-slate-500">{t("signup.checkEmail")}</div>
                </div>
                <div className="flex gap-4">
                  <Button className="flex-1 bg-slate-800" type="button" onClick={() => setStep(0)}>
                    {t("common.back")}
                  </Button>
                  <Button
                    className="flex-[2]"
                    disabled={!formErrors.validate({ fields: ["code"] })}
                    loading={submitting}
                    type="submit"
                  >
                    {t("common.next")}
                  </Button>
                </div>
                <FormErrors context={formErrors} exclude={["code"]} />
              </form>
            </MultiStepItem>
            <MultiStepItem step={2}>
              <form
                className="flex flex-col gap-8"
                onSubmit={hookSubmit(handleSubmit, { fields: ["name", "password", "passwordVerifier", "tos"] })}
              >
                <div className="flex flex-col gap-4">
                  <TextInput disabled label={t("signup.label.email")} type="email" value={email} />
                  <TextInput
                    autoComplete="name"
                    autofocus
                    errors={formErrors.getErrors("name")}
                    label={t("signup.label.name")}
                    type="name"
                    value={name}
                    onChange={setName}
                  />
                  <TextInput
                    autoComplete="new-password"
                    errors={formErrors.getErrors("password")}
                    label={t("signup.label.password")}
                    type="password"
                    value={password}
                    onChange={setPassword}
                  />
                  <TextInput
                    autoComplete="new-password"
                    errors={formErrors.getErrors("passwordVerifier")}
                    label={t("signup.label.passwordVerifier")}
                    type="password"
                    value={passwordVerifier}
                    onChange={setPasswordVerifier}
                  />
                  <div className="flex gap-2 items-start">
                    <input
                      className="mt-[3px]"
                      type="checkbox"
                      defaultChecked={tos}
                      onChange={() => setTOS((v) => !v)}
                    />
                    <span className="text-sm text-slate-600">
                      <Trans i18nKey="signup.tos">
                        By signing up, you agree to be bound by the{" "}
                        <a
                          className="inline self-center text-blue-600 p-0 m-0 hover:underline cursor-pointer"
                          href="/privacy.html"
                        >
                          privacy policy
                        </a>
                        ,{" "}
                        <a
                          className="inline self-center text-blue-600 p-0 m-0 hover:underline cursor-pointer"
                          href="/cookies.html"
                        >
                          cookie policy
                        </a>
                        , and{" "}
                        <a
                          className="inline self-center text-blue-600 p-0 m-0 hover:underline cursor-pointer"
                          href="/tos.html"
                        >
                          terms of service
                        </a>
                        .
                      </Trans>
                    </span>
                  </div>
                </div>
                <div className="flex gap-4">
                  <Button className="flex-1 bg-slate-800" type="button" onClick={() => setStep(1)}>
                    {t("common.back")}
                  </Button>
                  <Button
                    className="flex-[2]"
                    disabled={!formErrors.validate({ fields: ["name", "password", "passwordVerifier"] })}
                    loading={submitting}
                    type="submit"
                  >
                    {t("signup.submit")}
                  </Button>
                </div>
                <FormErrors context={formErrors} exclude={["name", "password", "passwordVerifier"]} />
              </form>
            </MultiStepItem>
          </>
        )}
      </MultiStepForm>
    </div>
  );
};

const SignUp = function SignUpPageComponent() {
  const navigate = useNavigate();

  const { search } = useLocation();

  const referral = useMemo(() => {
    const params = new URLSearchParams(search);

    return params.get("referral") || undefined;
  }, [search]);

  return (
    <div className="flex flex-grow flex-col">
      <Navigation />
      <div className="flex flex-col items-center">
        <div className="flex flex-col flex-grow w-full max-w-md gap-4 p-4">
          <SignUpForm onComplete={() => navigate("/")} onSignIn={() => navigate("/signin")} referral={referral} />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
