import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { HeartIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Navigation from "components/UserInterface/Navigation";
import * as config from "config";
import { twMerge } from "tailwind-merge";

const CopyButton = ({ className, text }: { className?: string; text: string }) => {
  const [copied, setCopied] = useState<NodeJS.Timeout | null>(null);

  const handleCopyCode = () => {
    navigator.clipboard.writeText(text);

    if (copied) clearTimeout(copied);

    setCopied(setTimeout(() => setCopied(null), 1000));
  };

  return (
    <button
      className={twMerge(classNames("px-4 bg-blue-600 text-white rounded-r", { "bg-green-600": copied }), className)}
      onClick={handleCopyCode}
    >
      {copied ? <CheckIcon className="w-6 h-6" /> : <DocumentDuplicateIcon className="w-6 h-6" />}
    </button>
  );
};

const Referral = function ReferralPageComponent() {
  const { t } = useTranslation();

  const link = useMemo(() => `${config.APP_URL}/signup`, []);

  return (
    <div className="flex flex-col grow">
      <Navigation />
      <div className="flex flex-col grow p-4 gap-8 w-full max-w-lg self-center pt-24">
        <div className="flex flex-col gap-4 items-center">
          <HeartIcon className="w-24 h-24 text-red-600" />
          <h1 className="text-2xl font-semibold text-center">{t("referral.title")}</h1>
          <div className="flex w-full border max-w-sm rounded mt-2">
            <input
              autoComplete="off"
              autoCorrect="off"
              className="p-4 grow text-lg bg-slate-200 text-slate-600"
              disabled
              value={link}
            />
            <CopyButton text={link} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referral;
