import makeRequest from "services/api/makeRequest";
import { ServiceType } from "services/api/types";

type TranslatePhraseData = { from?: string; to: string; phrase: string };
type TranslatePhrase = ServiceType<TranslatePhraseData, any>;

export const translatePhrase: TranslatePhrase = async function translatePhraseService(context, data) {
  const options = { data, method: "POST" };

  return makeRequest(context, "/translate/phrase", options);
};
