import { XMarkIcon } from "@heroicons/react/24/outline";

export interface PopupProps {
  buttons: React.ReactNode;
  children: React.ReactNode;
  icon?: React.ReactNode;
  onCancel?: () => void;
}

const ConfirmPopup = function ConfirmPopupComponent({ buttons, children, onCancel }: PopupProps) {
  return (
    <div className="relative flex flex-col items-stretch grow bg-white max-w-screen-md w-full mx-auto overflow-y-auto">
      {onCancel && (
        <button className="absolute top-2 right-2" onClick={onCancel}>
          <XMarkIcon className="w-8 h-8" />
        </button>
      )}
      {/* <div className="flex flex-col flex-1 justify-center items-center p-4 bg-slate-50">{icon}</div> */}
      <div className="flex grow flex-col justify-center shadow">
        <div className="flex flex-col items-center justify-center text-center p-4">{children}</div>
        <div className="flex self-center justify-stretch gap-4 p-4 max-w-sm w-full">{buttons}</div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
