import { Suspense, useRef } from "react";
import { createBrowserRouter, Navigate, Route, RouterProvider, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "components/Loader";
import { loadImage } from "data";
import { useAPI } from "services/api";
import { ContainerProvider } from "utils/container";
import Course from "./Course";
import CourseSetup from "./CourseSetup";
import { CourseContextProvider } from "./CourseContext";
import Lesson from "./Lesson";
import NotFound from "./NotFound";
import Profile from "./Profile";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import SignUpPrompt from "./SignUp/SignUpPrompt";
import Settings from "./Settings";
import Referral from "./Referral";
import VocabForm from "./Vocab/VocabForm";
import VocabStack from "./Vocab/VocabStack";
import VocabTrainer from "./Vocab/VocabTrainer";
import VocabReviewLesson from "components/Lesson/VocabReviewLesson";
import ResetPassword from "./ResetPassword";
import ErrorBoundary from "components/ErrorBoundary";

// Throws a promise that returns only when the API is initialized and the interface is ready.
const WaitForInitialization = () => {
  const { initialized } = useAPI();

  if (initialized) return null;

  throw new Promise(() => {});
};

const RequireNoAccount = ({ element }: { element: React.ReactNode }) => {
  const { user } = useAPI();

  if (user?.email) return <Navigate to="/course" />;

  return element;
};

const RequireAccount = ({ element }: { element: React.ReactNode }) => {
  const { user } = useAPI();

  if (!user?.email) return <Navigate to="/signup-to-continue" />;

  return element;
};

const UIRoutes = function UIRoutesComponent() {
  const containerRef = useRef(null);
  useAPI(); // Re-render when APIContext.initialized changes to update WaitForInitialization component.

  return (
    <div className="flex flex-col h-[100dvh] bg-slate-700 overflow-y-hidden">
      <ToastContainer position="bottom-right" autoClose={5000} draggable={false} pauseOnHover />
      <section
        className="flex flex-col flex-grow bg-white border-slate-400 w-full lg:max-w-screen-md relative mx-auto justify-center shadow-md overflow-y-hidden"
        ref={containerRef}
      >
        <CourseContextProvider>
          <ContainerProvider containerRef={containerRef}>
            <ErrorBoundary>
              <div className="flex flex-col flex-grow overflow-x-hidden overflow-y-auto">
                <Suspense
                  fallback={<Loader icon={<img src={loadImage("logo.svg")} alt="logo" />} className="animate-flip" />}
                >
                  <WaitForInitialization />
                  <Routes>
                    <Route index element={<Navigate to="/course" />} />
                    <Route path="/course" element={<Course />} />
                    <Route path="/lesson/vocab" element={<RequireAccount element={<VocabReviewLesson />} />} />
                    <Route path="/lesson/:lessonID/*" element={<Lesson />} />
                    <Route path="/profile/:userID?" element={<Profile />} />
                    <Route path="/referral" element={<RequireAccount element={<Referral />} />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/settings" element={<RequireAccount element={<Settings />} />} />
                    <Route path="/setup" element={<CourseSetup />} />
                    <Route path="/signin" element={<RequireNoAccount element={<SignIn />} />} />
                    <Route path="/signup" element={<RequireNoAccount element={<SignUp />} />} />
                    <Route path="/signup-to-continue" element={<RequireNoAccount element={<SignUpPrompt />} />} />
                    <Route path="/vocab" element={<RequireAccount element={<VocabTrainer />} />} />
                    <Route path="/vocab/stack" element={<RequireAccount element={<VocabStack />} />} />
                    <Route path="/vocab/edit/:vocabularyCardID?" element={<RequireAccount element={<VocabForm />} />} />
                    <Route path="*" element={<NotFound />}></Route>
                  </Routes>
                </Suspense>
              </div>
            </ErrorBoundary>
          </ContainerProvider>
        </CourseContextProvider>
      </section>
    </div>
  );
};

const router = createBrowserRouter([{ path: "*", element: <UIRoutes /> }]);

export default function UserInterface() {
  return <RouterProvider router={router} />;
}
