export type Suspense<T> = { read: () => T };

export const TriggerSuspense = ({ suspense }: { suspense: Suspense<any> }) => suspense.read();

/**
 * Suspensify a promise.
 */
export default function suspensify<T>(promise: Promise<T>): Suspense<T> {
  let status = 0;
  let result: T;

  const suspender = promise.then(
    (res) => {
      status = 1;
      result = res;
    },
    (error) => {
      status = -1;
      result = error;
    }
  );

  return {
    read() {
      if (status === 0) throw suspender;
      if (status === -1) throw result;

      return result;
    },
  };
}
