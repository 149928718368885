import React from "react";

interface TitleProps {
  content: {
    text: string;
  };
}

const Title: React.FC<TitleProps> = function TitleComponent({ content: { text } }) {
  return <h1 className="font-semibold text-lg">{text}</h1>;
};

export default Title;
