import React, { useEffect } from "react";

import { useLesson } from "components/Lesson/LessonContext";
import Medium from "./Medium";
import { useAPI } from "services/api";

const VocabularyComponent: React.FC = function VocabularyComponent() {
  const [medium, setMedium] = React.useState<any>(null);
  const { lesson } = useLesson();
  const { services } = useAPI();

  useEffect(() => {
    if (!lesson) return;

    services.queryMedium({ mediumID: lesson.mediumID }).then(setMedium);
  }, [lesson]);

  if (!medium) return null;
  return (
    <div className="grow py-6 pb-10 px-3 md:px-7 h-0 overflow-y-auto">
      <Medium {...medium} />
    </div>
  );
};

export default VocabularyComponent;
