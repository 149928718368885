import React from "react";

export interface LessonContextType {
  exercisePointer: number;
  lesson: any;
  lessonProgress: number;
  audio: boolean;
  toggleVocabularyView: () => void;
  vocabularyView: boolean;
  microphoneAvailable: boolean | null;
  toggleAudio: () => void;
  onComplete: (...args: any) => void;
  onPreComplete: (...args: any) => void;
}

const LessonContext = React.createContext<LessonContextType | Record<string, never>>({});

const useLesson = function useLessonContext() {
  return React.useContext(LessonContext);
};

export default LessonContext;
export { useLesson };
