import React, { useState } from "react";
import { SpeakerWaveIcon, SpeakerXMarkIcon } from "@heroicons/react/24/solid";
import { LanguageIcon, XMarkIcon, BookOpenIcon } from "@heroicons/react/24/outline";
import { useLesson } from "components/Lesson/LessonContext";
import classNames from "classnames";
import { TranslateInput } from "./Inputs";
import twm from "utils/twm";

interface InputAndControlsProps {
  children?: React.ReactNode;
  disabled?: boolean;
  expand?: boolean;
  vocabularyScreen?: boolean;
}

export const InputAndControls: React.FC<InputAndControlsProps> = function InputAndControlsComponent({
  children,
  disabled = false,
  // expand = false,
  // vocabularyScreen = true,
}) {
  const { vocabularyView } = useLesson();
  const [translate] = useState(false);

  return (
    <div className={classNames("flex relative box-border p-3 gap-4 border-t border-slate-100", {})}>
      {/* !vocabularyView && (
          <div
            className={twm("pl-2 py-1 mb-px w-10 flex items-center justify-start gap-2", {
              "sm:flex hidden": expand,
              "w-10": translate,
            })}
          >
            <>
              {translate ? (
                <XMarkIcon
                  className={classNames("w-6 h-6 cursor-pointer hover:text-red-600 stroke-[2.5px] text-slate-400", {})}
                  onClick={() => setTranslate((prev) => !prev)}
                />
              ) : (
                <LanguageIcon
                  className={classNames("w-6 h-6 cursor-pointer hover:text-green-600 stroke-2", {
                    "text-blue-500": translate,
                    "text-slate-400": !translate,
                  })}
                  onClick={() => setTranslate((prev) => !prev)}
                />
              )}
            </>
          </div>
        ) */}
      <div
        className={classNames(
          "flex grow flex-col items-stretch justify-center",
          { "opacity-50": disabled && !translate && !vocabularyView },
          { "opacity-100": (!disabled || translate) && !vocabularyView },
          { "opacity-0": vocabularyView },
        )}
      >
        {translate ? <TranslateInput /> : children}
      </div>
      {/* <div
          className={twm("sm:hidden pr-1.5 py-1 mb-px w-10 flex items-center justify-end gap-2", {
            "sm:flex hidden": expand,
            hidden: translate,
          })}
        >
          {vocabularyScreen && !translate && <div className="h-10 flex items-center py-1"></div>}
        </div> */}
    </div>
  );
};
