import { useCallback, useState } from "react";
import validator from "validator";

import { Button, TextInput, useForm, Validations, FormErrors, MultiStepForm, MultiStepItem } from "components/form";
import { useAPI } from "services/api";
import { APIUser } from "services/api/APIContext";
import { useTranslation } from "react-i18next";

type EmailFormProps = { onComplete?: () => void; user: APIUser };

const EmailForm = function EmailFormComponent({ onComplete, user }: EmailFormProps) {
  const [email, setEmail] = useState(user.email || "");
  const [code, setCode] = useState("");
  const [verificationID, setVerificationID] = useState("");

  const { reauth, services } = useAPI();
  const { t } = useTranslation();

  const validations = [
    [["email"], validator.isEmail, t("common.error.emailInvalid")],
    [["code"], (v: string) => v.length === 6, t("common.error.codeInvalid")],
  ] as Validations;

  const { dirty, formErrors, hookSubmit, resetInitialValues, submitting } = useForm({
    fields: { email, code },
    validations,
  });

  const handleReset = useCallback(
    (cb: (val: number) => void) => {
      setEmail(user.email || "");
      setCode("");
      cb(0);

      if (onComplete) onComplete();
    },
    [onComplete, setEmail, setCode, user]
  );

  const handleSubmitEmail = useCallback(async () => {
    const response = await services.initEmailVerification({ email, type: "new" });
    setVerificationID(response.verificationID);
  }, [email, services, setVerificationID]);

  const handleSubmitCode = useCallback(async () => {
    const response = await services.completeEmailVerification({ code, email, verificationID });

    const { emailVerifier } = response;

    await services.updateUserEmail({ email, emailVerifier });
    await reauth();

    setCode("");
    setVerificationID("");

    resetInitialValues();

    if (onComplete) onComplete();
  }, [code, email, services, setCode, setVerificationID, verificationID]);

  return (
    <MultiStepForm steps={2}>
      {({ incrementStep, setStep }) => (
        <>
          <MultiStepItem step={0}>
            <form
              className="flex flex-col gap-2"
              onSubmit={hookSubmit(
                async () => {
                  await handleSubmitEmail();
                  incrementStep();
                },
                { fields: ["email"] }
              )}
            >
              <TextInput
                autoComplete="email"
                autofocus
                errors={formErrors.getErrors("email")}
                label="Email"
                type="email"
                value={email}
                onChange={setEmail}
              />
              <div className="flex justify-center gap-4">
                <Button disabled={submitting} type="button" onClick={() => handleReset(setStep)}>
                  {t("common.cancel")}
                </Button>
                <Button
                  className="self-center"
                  disabled={!dirty || submitting || !formErrors.validate({ fields: ["email"] })}
                  loading={submitting}
                  type="submit"
                >
                  {t("common.sendVerificationCode")}
                </Button>
              </div>
              <FormErrors context={formErrors} exclude={["email"]} />
            </form>
          </MultiStepItem>
          <MultiStepItem step={1}>
            <form className="flex flex-col gap-2" onSubmit={hookSubmit(handleSubmitCode, { fields: ["code"] })}>
              <TextInput disabled label="Email" value={email} onChange={setEmail} />
              <TextInput
                autoComplete="one-time-code"
                autofocus
                errors={formErrors.getErrors("code")}
                label="Code"
                onChange={setCode}
                pattern="[0-9]*"
                type="text"
                value={code}
              />
              <div className="flex justify-center gap-4">
                <Button type="button" onClick={() => handleReset(setStep)}>
                  {t("common.cancel")}
                </Button>
                <Button
                  disabled={!dirty || submitting || !formErrors.validate({ fields: ["code"] })}
                  loading={submitting}
                  type="submit"
                >
                  {t("common.save")}
                </Button>
              </div>
              <FormErrors context={formErrors} exclude={["code"]} />
            </form>
          </MultiStepItem>
        </>
      )}
    </MultiStepForm>
  );
};

export default EmailForm;
