import i18next from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { APIError } from "services/api";

export type FormError = { fields?: string[]; message: string; timeout?: number };

type ValidateFunction = (options?: { fields?: string[]; ignoreEmpty?: boolean; store?: boolean }) => boolean;

type FormErrorContextType = {
  addError: (error: any) => void;
  clearErrors: () => void;
  errors: FormError[];
  getErrors: (field: string) => FormError[] | undefined;
  hasError: (field?: string) => boolean;
  validate: ValidateFunction;
};

export type Validation = [fieldKeys: string[], func: (...args: any[]) => boolean, message: string];
export type Validations = Validation[];

export type UseFormErrorsProps = {
  fields: { [key: string]: any };
  validateOnChange?: boolean;
  validations?: Validations;
};

export const parseAPIError = (error: APIError): FormError => {
  let { message = "" } = error;
  const { code, fields, timeout } = error.error.response?.data || {};

  if (code)
    message = i18next.t(`api.error.${code}`, {
      defaultValue: code ? i18next.t("api.error.unknownCode", { code }) : i18next.t("api.error.unknown"),
      fields,
      timeout,
    });

  return { fields, message, timeout };
};

const useFormErrors = ({ fields, validateOnChange = true, validations }: UseFormErrorsProps) => {
  const [errors, setErrors] = useState<FormError[]>([]);
  const [validateTimeout, setValidateTimeout] = useState<NodeJS.Timeout | null>(null);

  const { t } = useTranslation();

  const addError = useCallback(
    (error: FormError | APIError) => {
      if (!("isAPIError" in error)) {
        setErrors((prev) => [...prev, error as FormError]);
        return;
      }

      setErrors((prev) => [...prev, parseAPIError(error)]);
    },
    [setErrors, t]
  );

  const clearErrors = useCallback(() => setErrors([]), [setErrors]);

  const hasError = useCallback(
    (field?: string) => (field ? errors.some((error) => error.fields?.includes(field)) : Boolean(errors.length)),
    [errors]
  );

  const getErrors = useCallback((field: string) => errors.filter((error) => error.fields?.includes(field)), [errors]);

  const validate = useCallback<ValidateFunction>(
    ({ fields: fieldSubset, ignoreEmpty = false, store = false } = {}) => {
      if (!validations) return true;

      let activeValidations = validations;
      if (fieldSubset) activeValidations = validations.filter((val) => val[0].some((key) => fieldSubset.includes(key)));

      if (!activeValidations) return true;

      const results = activeValidations.map(([fieldKeys, func, message]) => {
        const values = Object.entries(fields)
          .filter((field) => fieldKeys.includes(field[0]))
          .map((field) => field[1]);

        if (ignoreEmpty && !values.some((v: any) => Boolean(v))) return true;

        const result = func(...Object.values(values));

        if (store && !result) addError({ fields: fieldKeys, message });

        return result;
      });

      return results.every((v) => v);
    },
    [addError, fields, validations]
  );

  const context: FormErrorContextType = useMemo(
    () => ({ addError, clearErrors, errors, getErrors, hasError, validate }),
    [addError, clearErrors, errors, getErrors, hasError, validate]
  );

  useEffect(() => {
    clearErrors();

    if (validateTimeout) clearTimeout(validateTimeout);

    if (!validateOnChange || !Object.values(fields).some((v: any) => Boolean(v))) return;

    setValidateTimeout(setTimeout(() => validate({ ignoreEmpty: true, store: true }), 500));
  }, [...Object.values(fields)]);

  return context;
};

const FormErrors = ({ context, exclude = [] }: { context: FormErrorContextType; exclude?: string[] }) => (
  <div className="flex flex-col gap-2">
    {context.errors
      .filter((e) => !exclude.some((ex) => e.fields?.includes(ex)))
      .map((error, idx) => (
        <div className="text-red-600 text-sm" key={idx}>
          {error.message}
        </div>
      ))}
  </div>
);

export default FormErrors;
export { useFormErrors };
