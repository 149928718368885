import makeRequest from "services/api/makeRequest";
import { ServiceType } from "services/api/types";

export type Timestamp = { timestamp: number; word: string };
export type SpeakData = { speakerID: string; speed: string; text: string; timestamps: Timestamp[] };
export type Speak = ServiceType<SpeakData, any>;

export const speak: Speak = async function speakService(context, data) {
  const options = { data, method: "POST" };

  return makeRequest(context, "/speak", options);
};
