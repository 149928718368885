import makeRequest from "services/api/makeRequest";
import { ServiceType } from "services/api/types";

export type ActiveUser = { exerciseCount: number; id: string; name: string };
export type StatData = object;
export type StatReturn = { activeUserCount: number; activeUsers: ActiveUser[] };
export type Stat = ServiceType<StatData, StatReturn>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const queryStat: Stat = async function queryStatService(context, data = {}) {
  const options = { method: "GET", unauthenticated: true };

  return makeRequest(context, "/stat", options);
};
