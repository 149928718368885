import React, { useMemo } from "react";
// import { useTranslation } from "react-i18next";

import HoverPopup from "components/HoverPopup";
import { useTranslations } from "services/api/TranslationContext";
import suspensify, { TriggerSuspense } from "utils/suspensify";
import Loader from "components/Loader";
import { twMerge } from "tailwind-merge";
import twm from "utils/twm";

type TranslatePopupProps = {
  word: string;
};

const TranslatePopup = function TranslatePopupComponent({ word }: TranslatePopupProps) {
  // const { t } = useTranslation();
  const { lookupWord } = useTranslations();

  const translation = useMemo(() => suspensify(lookupWord(word)), [word]);

  return (
    <span className="flex flex-col">
      <React.Suspense fallback={<Loader />}>
        <span className="text-slate-600 tracking-wide not-italic">
          <TriggerSuspense suspense={translation} />
        </span>
      </React.Suspense>
    </span>
  );
};

type SelectableWordProps = {
  className: string;
  word: string;
  disable?: boolean;
};

/**
 * Renders a selectable word that, when hovered over, shows a translation of the word.
 * Requires the component to be wrapped in a WordTranslationContext.
 */
const SelectableWord = function SelectableWordComponent({ className, word, disable = false }: SelectableWordProps) {
  const cleanedWord = word.replace(/=/gu, "");
  const popup = useMemo(() => <TranslatePopup word={cleanedWord} />, [cleanedWord]);

  const parts = word.split(/(=)/gu);
  let emphasis = false;
  return (
    <HoverPopup popup={popup} popupMargin={20} disable={disable}>
      <span
        className={twMerge(
          "cursor-pointer select-none text-slate-600 group-hover/hover-popup:text-green-700 hover:text-green-700 after:absolute after:h-[2px] after:inset-x-0 after:bottom-1.5 after:bg-gray-300 group-hover/hover-popup:after:bg-green-500 hover:after:bg-green-500",
          className,
        )}
      >
        {parts.map((part, index) => {
          if (part === "=") {
            emphasis = !emphasis;
            return null;
          }
          return (
            <span key={index} className={twm({ "text-red-500": emphasis })}>
              {part}
            </span>
          );
        })}
      </span>
    </HoverPopup>
  );
};

export default SelectableWord;
