import { useMemo } from "react";

import DialogueMedium from "./DialogueMedium";
import ImageMedium from "./ImageMedium";
import SoundMedium from "./SoundMedium";
import CompositeMedium from "./CompositeMedium";
import { MediumProps, MediumTypeProps } from "./types";

const mediumComponents: { [key: string]: React.FC<MediumTypeProps> } = {
  dialogue: DialogueMedium,
  image: ImageMedium,
  sound: SoundMedium,
  composite: CompositeMedium,
};

const Medium = function MediumComponent({ type, ...rest }: MediumProps) {
  const MediumTypeComponent = useMemo(() => mediumComponents[type], [type]);

  return <MediumTypeComponent {...rest} />;
};

export default Medium;
