import { APIContextType } from "services/api/APIContext";
import makeRequest from "services/api/makeRequest";
import { ServiceType, User, UserStatistics } from "services/api/types";

const setTokens = (context: APIContextType, response: any) => {
  localStorage.setItem("refreshToken", response.refreshToken);
  context.setAccessToken(response.accessToken);
};

type ReauthData = { refreshToken: string };
type Reauth = ServiceType<ReauthData, any>;

export const reauth: Reauth = async function reauthService(c, data) {
  const options = { data, method: "POST", reattempt: false, unauthenticated: true };

  const response = await makeRequest(c, `/user/reauth`, options);
  setTokens(c, response);

  return response;
};

type SignInData = { email: string; password: string };
type SignIn = ServiceType<SignInData, any>;

export const signIn: SignIn = async function signInService(c, data) {
  const options = { data, method: "POST", unauthenticated: true };

  const response = await makeRequest(c, `/user/signin`, options);
  setTokens(c, response);

  return response;
};

type SignUpData = { email: string; emailVerifier: string; name: string; password: string; referralCode: string };
type SignUp = ServiceType<SignUpData, any>;

export const signUp: SignUp = async function signUpService(c, data) {
  const options = { data, method: "POST" };

  const response = await makeRequest(c, `/user/signup`, options);

  return response;
};

type CreateUserData = object;
type CreateUser = ServiceType<CreateUserData, any>;

export const createUser: CreateUser = async function createUserService(c, data) {
  const options = { data, method: "POST", unauthenticated: true };

  const response = await makeRequest(c, `/user`, options);
  setTokens(c, response);

  return response;
};

type QueryUserData = { userID: string };
type QueryUserResult = { user: User; statistics: UserStatistics };
type QueryUser = ServiceType<QueryUserData, QueryUserResult>;

export const queryUser: QueryUser = async function queryUserService(c, { userID }) {
  const options = { method: "GET" };

  const response = await makeRequest(c, `/user/${userID}`, options);

  return response;
};

type UpdateUserData = { name: string };
type UpdateUser = ServiceType<UpdateUserData, Record<string, never>>;

export const updateUser: UpdateUser = async function updateUserService(c, data) {
  const options = { data, method: "POST" };

  const response = await makeRequest(c, `/user/update`, options);

  return response;
};

type UpdateUserEmailData = { email: string; emailVerifier: string };
type UpdateUserEmail = ServiceType<UpdateUserEmailData, Record<string, never>>;

export const updateUserEmail: UpdateUserEmail = async function updateUserEmailService(c, data) {
  const options = { data, method: "POST" };

  const response = await makeRequest(c, `/user/update-email`, options);

  return response;
};

type UpdateUserPasswordData = { password: string; newPassword: string };
type UpdateUserPassword = ServiceType<UpdateUserPasswordData, Record<string, never>>;

export const updateUserPassword: UpdateUserPassword = async function updateUserPasswordService(c, data) {
  const options = { data, method: "POST" };

  const response = await makeRequest(c, `/user/update-password`, options);

  return response;
};

type DeleteUserData = { password: string };
type DeleteUser = ServiceType<DeleteUserData, Record<string, never>>;

export const deleteUser: DeleteUser = async function deleteUserService(c, data) {
  const options = { data, method: "DELETE" };

  const response = await makeRequest(c, `/user`, options);

  return response;
};
