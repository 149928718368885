import makeRequest from "services/api/makeRequest";
import { ServiceType } from "services/api/types";

type ProofreadData = { instruction: string; language: string; sentence: string };
type Proofread = ServiceType<ProofreadData, any>;

export const proofread: Proofread = async function proofreadService(context, data) {
  const options = { data, method: "POST" };

  return makeRequest(context, "/proofread", options);
};
