// import twm from "utils/twm";
import React, { useMemo, useState } from "react";

import { MediumTypeProps } from "./types";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

interface SoundData {
  description: string;
  file: string;
  transcript: string;
}

interface SoundMediumProps extends MediumTypeProps {
  data: SoundData;
}

const SoundMedium: React.FC<SoundMediumProps> = function SoundMediumComponent({ data, speechSynthID }) {
  // const [finishedOnce, setFinishedOnce] = useState(false);
  // const [play, setPlay] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);
  // const { audio: volume } = useLesson();
  const { file } = useMemo(() => data, [data]);
  const { t } = useTranslation();

  // const { onAudioEnd, pauseAudio, playAudio, setAudioVolume } = useAudio();

  // const { onSpeechSynthComplete } = useSpeechSynth({
  //   interrupt: () => setPlay(false),
  //   speechSynthID,
  //   trigger: () => setPlay(true),
  // });

  // useEffect(() => {
  //   if (!play) return () => {};

  //   playAudio(file);
  //   setAudioVolume(volume ? 1 : 0, data.file);
  //   onAudioEnd(() => setPlay(false));

  //   if (finishedOnce) return () => pauseAudio(data.file);

  //   setFinishedOnce(true);

  //   return () => {
  //     onSpeechSynthComplete();
  //     pauseAudio(data.file);
  //   };
  // }, [play]);

  // useEffect(() => {
  //   setAudioVolume(volume ? 1 : 0, data.file);
  // }, [volume]);

  return (
    <div className="flex flex-col items-start gap-2">
      <div className="self-center py-2">
        <audio id="audio" controls preload="auto">
          <source src={file} type="audio/mpeg" />
          Your browser does not support HTML5 audio.
        </audio>
      </div>
        {!showTranscript && (
          <div className="grow shrink-0 w-full flex justify-start text-sm leading-2 pr-2">
            <div className="hover:underline cursor-pointer" onClick={() => setShowTranscript((prev) => !prev)}>
              {t("medium.sound.showTranscript")}
            </div>
          </div>
        )}
      {showTranscript && (
        <div className="w-full">
          <p>
            {data.transcript}{" "}
            <XMarkIcon
              className="w-5 h-5 inline stroke-2 hover:text-red-500 cursor-pointer -mt-0.5"
              onClick={() => setShowTranscript(false)}
            />
          </p>
        </div>
      )}
    </div>
  );
};

// <button
//   className={twm("relative text-white transition-colors hover:text-slate-100")}
//   onClick={() => setPlay((prev) => !prev)}
// >
//   {play ? <StopIcon className="w-9 h-9" /> : <PlayIcon className="w-9 h-9" />}
// </button>
// <div className="grow overflow-hidden mr-5 relative flex items-center justify-start h-4">
//   {play ? (
//     <div className="flex justify-center items-center gap-px absolute left-0 top-0 h-4">
//       {Array.from(Array(100)).map((_, i) => (
//         <div
//           key={i}
//           style={{ animationDuration: `${Math.random() * (1 - 0.2) + 0.2}s` }}
//           className={
//             "w-px h-2 animate-wave-lg [&:nth-child(-n+3)]:animate-wave-md [&:nth-last-child(-n+3)]:animate-wave-md [&>:nth-child(-n+7)]:animate-wave-sm [&>:nth-last-child(-n+7)]:animate-wave-sm bg-white"
//           }
//         >
//           {" "}
//         </div>
//       ))}
//     </div>
//   ) : (
//     <div className="h-px bg-white grow" />
//   )}

export default SoundMedium;
