import React, { useState } from "react";
import { ReadText } from "components/Text";
import twm from "utils/twm";
import { LanguageIcon, XMarkIcon } from "@heroicons/react/24/outline";
import TranslateLine from "components/Lesson/Exercise/shared/TranslateLine";

interface DescriptionProps {
  content: { text: string; plain?: boolean };
  speechSynthID?: string;
  className?: string;
  instructionSpeakerID?: string;
  setSpeechSynthID?: (_: string | null) => void;
}

const PlainDescription = function PlainDescriptionComponent({ text }: { text: string }) {
  return <ReadText text={text} plainText={true} />;
};

const InteractiveDescription: React.FC<DescriptionProps> = function InteractiveDescriptionComponent({
  content,
  speechSynthID,
  instructionSpeakerID,
  className,
}) {
  const [showTranslation, setShowTranslation] = useState<boolean>(false);
  return (
    <span className="flex flex-row justify-between items-start">
      <TranslateLine phrase={content.text} className={twm("mr-2", { hidden: !showTranslation })} />
      <ReadText
        className={twm(className, "inline mr-2", { hidden: showTranslation })}
        text={content.text}
        plainText={content?.plain}
        speechSynthID={speechSynthID}
        speakerID={instructionSpeakerID}
      />
      <div className="mt-1">
        {showTranslation ? (
          <button
            onClick={() => setShowTranslation(false)}
            className="flex items-center justify-center p-1.5 rounded-full bg-red-500 hover:bg-red-600"
          >
            <XMarkIcon className="w-[17px] h-[17px] text-white cursor-pointer inline-block !stroke-[2.5] shrink-0" />
          </button>
        ) : (
          <button
            onClick={() => setShowTranslation(true)}
            className="flex items-center justify-center p-1.5 rounded-full bg-blue-500 hover:bg-blue-600"
          >
            <LanguageIcon className="w-[17px] h-[17px] inline-block cursor-pointer !stroke-[2.5] text-white shrink-0" />
          </button>
        )}
      </div>
    </span>
  );
};

const Description: React.FC<DescriptionProps> = function DescriptionComponent({
  content,
  speechSynthID,
  instructionSpeakerID,
  setSpeechSynthID,
  className,
}) {
  if (content?.plain) return <PlainDescription text={content.text} />;

  return (
    <InteractiveDescription
      content={content}
      speechSynthID={content.plain ? undefined : speechSynthID}
      instructionSpeakerID={content.plain ? undefined : instructionSpeakerID}
      setSpeechSynthID={setSpeechSynthID}
      className={className}
    />
  );
};

export default Description;
