import { ArrowLongRightIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useTranslation } from "react-i18next";

import { loadImage } from "data";
import { CEFRCode } from "utils/cefr";
import { LanguageCode, languageCodeToFlag } from "utils/languages";
import { useCourseContext } from "../CourseContext";

const loadImageFromCode = (code: LanguageCode) => loadImage(languageCodeToFlag(code));

type LanguageFieldProps = { languageCode?: LanguageCode };

export const LanguageField: React.FC<LanguageFieldProps> = function LanguageFieldComponent({ languageCode }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-shrink-0 gap-2 items-center text-slate-600">
      {!languageCode ? (
        <div className="w-4 aspect-[4/3] bg-slate-700"></div>
      ) : (
        <img src={loadImageFromCode(languageCode as LanguageCode)} alt="flag" className="w-4 shadow-sm " />
      )}
      <div className="font-semibold text-sm">{!languageCode ? t("select") : t(`common.language.${languageCode}`)}</div>
    </div>
  );
};

type CourseSelectionProps = {
  language?: LanguageCode;
  level?: CEFRCode;
  instruction?: LanguageCode;
};

/**
 * A component that visualizes the current course selection progression.
 */
const CourseSelection: React.FC<CourseSelectionProps> = function CourseSelectionComponent({
  language,
  level,
  instruction,
}) {
  const { setupComplete } = useCourseContext();
  if (!setupComplete) return null;
  return (
    <div className="flex flex-row flex-shrink-0 items-center gap-2">
      <LanguageField languageCode={instruction} />
      <ArrowLongRightIcon className="inline-block flex-shrink-0 w-5 h-5 text-slate-700" />
      <LanguageField languageCode={language} />
      <div className="flex items-center text-sm font-semibold text-slate-600 -ml-1">
        {level ? level.toUpperCase() : "X#"}
      </div>
    </div>
  );
};

export default CourseSelection;
