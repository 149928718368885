import { useCallback, useEffect, useMemo, useState } from "react";

import { useCourseContext } from "components/UserInterface/CourseContext";
import { useAPI } from "services/api";
import { CompleteExerciseButtonButton } from "./Exercise/shared/CompleteExerciseButton";
import { TranslationProvider } from "services/api/TranslationContext";
import HoverPopup from "components/HoverPopup";
import ProgressBar from "components/ProgressBar";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { LESSON_CHECKPOINTS } from "./config";
import { ListVocabularyCardsResult, ScheduleVocabularyCardResult } from "services/api/routes";
import { useNavigate } from "react-router-dom";
import { VocabTrainer } from "components/UserInterface/Vocab/VocabTrainer";
import { ScrollChat } from "./Exercise/shared";

type LessonProps = { maxCount?: number };

const VocabReviewLesson = function VocabReviewLessonComponent({ maxCount = 15 }: LessonProps) {
  if (maxCount > 100) throw new Error("VocabReviewLesson: maxCount must be less than 100");

  const navigate = useNavigate();
  const { services } = useAPI();
  const { language, instruction } = useCourseContext();

  const [stack, setStack] = useState<ListVocabularyCardsResult["vocabularyCards"]>([]);
  const [count, setCount] = useState(maxCount);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchStack = useCallback(async () => {
    setLoading(true);

    let result;
    try {
      result = await services.listVocabularyCards({ limit: count, due: true, language, instruction });
    } catch (error) {
      return;
    } finally {
      setLoading(false);
    }

    setStack(result.vocabularyCards);
    setCount(Math.min(result.count, maxCount));
  }, [maxCount, services]);

  useEffect(() => {
    fetchStack();
  }, []);

  const handleVocabComplete = useCallback(
    (card: ScheduleVocabularyCardResult) => {
      setStack((s) => {
        let newStack = s.filter((c) => c.id !== card.id);
        if (new Date(card.dueAt) < new Date()) newStack = [...newStack, card];

        newStack = newStack.sort((a, b) => new Date(a.dueAt).getTime() - new Date(b.dueAt).getTime());

        return newStack;
      });
    },
    [setStack]
  );

  const handleComplete = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const progress = useMemo(() => Math.max(0, Math.round(1 - (stack.length / count) * 100)), [count, stack]);

  if (!language || !instruction) return null;
  return (
    <TranslationProvider from={language} to={instruction}>
      <div className="flex items-center bg-white py-3 px-2 gap-3 sm:px-5 shadow-sm h-16 border-b border-gray-100">
        <button
          className="cursor-pointer text-slate-500 hover:text-red-600 transition-colors"
          onClick={() => navigate("/")}
        >
          <ArrowRightOnRectangleIcon className="w-6 h-6 self-center transform rotate-180" />
        </button>
        <div className="flex flex-grow flex-col">
          <HoverPopup caret={false} popup={`Lesson progress: ${progress}%`}>
            <ProgressBar checkpoints={LESSON_CHECKPOINTS} progress={progress} />
          </HoverPopup>
        </div>
        <HoverPopup
          caret={false}
          className="text-slate-500 text-md cursor-pointer hover:text-blue-500 transition-colors"
          popup={`${count - stack.length} out of ${count} cards completed.`}
          popupClassName="text-black"
        >
          {count - stack.length}/{count}
        </HoverPopup>
      </div>
      <ScrollChat>
        <div className="h-1" />
        <VocabTrainer count={stack.length} loading={loading} onComplete={handleVocabComplete} stack={stack}>
          <div className="flex flex-col grow justify-end">
            {stack.length === 0 && (
              <CompleteExerciseButtonButton
                className="py-2"
                continueText=""
                handleClick={handleComplete}
                isLastExercise={true}
              />
            )}
            <div className="">&nbsp;</div>
          </div>
        </VocabTrainer>
      </ScrollChat>
    </TranslationProvider>
  );
};

export default VocabReviewLesson;
