import { useTranslation } from "react-i18next";

import Navigation from "components/UserInterface/Navigation";
import { Link } from "react-router-dom";
import { HeartIcon } from "@heroicons/react/24/solid";

const SignUpPrompt = function SignUpPromptPageComponent() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col grow">
      <Navigation />
      <div className="flex flex-col p-4 gap-8 w-full self-center pt-24">
        <div className="flex justify-center relative">
          <HeartIcon className="self-center w-24 h-24 text-red-500 animate-microphone" />
          <HeartIcon className="absolute self-center w-24 h-24 text-red-500" />
        </div>
        <h1 className="text-xl font-semibold text-center">{t("signup.requestSignUpTitle")}</h1>
        <Link
          className="self-center px-8 py-2 rounded bg-green-500 hover:bg-green-600 text-white transition-colors font-semibold"
          to="/signup"
        >
          {t("common.signup")}
        </Link>
      </div>
    </div>
  );
};

export default SignUpPrompt;
