import makeRequest from "services/api/makeRequest";
import { ServiceType } from "services/api/types";
import { OpenAIChatMessage } from "types";

export type QueryExerciseData = { exerciseID: string };
export type QueryExercise = ServiceType<QueryExerciseData, any>;

export const queryExercise: QueryExercise = async function queryExerciseService(c, { exerciseID }: QueryExerciseData) {
  const options = { method: "GET" };

  return makeRequest(c, `/exercise/${exerciseID}`, options);
};

export type EvaluateDiscussionData = { exerciseID: string; stream: string | null; messages: OpenAIChatMessage[] };
export type EvaluateDiscussion = ServiceType<EvaluateDiscussionData, any>;

export const evaluateOpenQuestion: EvaluateDiscussion = async function evaluateOpenQuestionService(
  c,
  data: EvaluateDiscussionData
) {
  const options = { data, method: "POST" };

  return makeRequest(c, "/exercise/discussion/evaluate", options);
};

export type EvaluateConversationData = { exerciseID: string; stream: string | null; messages: OpenAIChatMessage[] };
export type EvaluateConversation = ServiceType<EvaluateConversationData, any>;

export const evaluateConversation: EvaluateConversation = async function evaluateConversationService(
  c,
  data: EvaluateConversationData
) {
  const options = { data, method: "POST" };

  return makeRequest(c, "/exercise/conversation/evaluate", options);
};

export type CompleteExerciseData = { exerciseID: string };
export type CompleteExercise = ServiceType<CompleteExerciseData, any>;

export const completeExercise: CompleteExercise = async function completeExerciseService(c, { exerciseID }) {
  const options = { method: "POST" };

  return makeRequest(c, `/exercise/${exerciseID}/complete`, options);
};
