import { BookOpenIcon, Cog6ToothIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { FireIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import HoverPopup from "components/HoverPopup";
import { useCourseContext } from "components/UserInterface/CourseContext";
import { loadImage } from "data";
import { useAPI } from "services/api";
import { Lesson, User, UserStatistics } from "services/api/types";
import { LanguageCode, languageCodeToFlag } from "utils/languages";
import usePagination from "utils/usePagination";
import Loader from "components/Loader";

type StatisticProps = { description?: string; icon: React.ReactNode; title: string; value: string };

const Statistic = ({ description, icon, title, value }: StatisticProps) => (
  <HoverPopup
    caret={false}
    className={classNames("flex flex-col items-stretch bg-slate-50 py-4 px-6 rounded shadow", {
      "cursor-pointer": description,
    })}
    popup={description && <div className="">{description}</div>}
  >
    <div className="flex">
      {icon}
      <span className="text-lg font-bold">{value}</span>
    </div>
    <p className="text-sm text-gray-500">{title}</p>
  </HoverPopup>
);

const Separator = () => <div className="border-b border-2 border-gray-200 mt-4" />;

const SectionTitle = ({ children }: { children: React.ReactNode }) => (
  <h2 className="text-lg font-semibold mt-4">{children}</h2>
);

type ProfileButtonProps = { children: React.ReactNode; className?: string; [key: string]: any };

const ProfileButton = ({ children, className, ...rest }: ProfileButtonProps) => (
  <button
    className={twMerge(
      "flex items-center gap-2 border border-slate-300 rounded px-4 py-2 hover:bg-slate-100 text-slate-600 font-semibold",
      className
    )}
    {...rest}
  >
    {children}
  </button>
);

const LessonCard = ({ lesson }: { lesson: Lesson }) => {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col items-stretch bg-slate-50 py-4 px-6 rounded shadow cursor-pointer hover:bg-slate-100 transition-colors"
      onClick={() => navigate(`/lesson/${lesson.id}`)}
    >
      <div className="flex">
        <span className="text-lg font-bold">{lesson?.title}</span>
      </div>
      <p className="text-sm text-gray-500">{lesson?.description}</p>
    </div>
  );
};

const CompletedLessons = ({ instruction, language }: { instruction?: string; language?: string }) => {
  const { services } = useAPI();
  const { t } = useTranslation();

  const load = useCallback(
    async ({ limit, offset }: { limit: number; offset: number }) => {
      let response;
      try {
        response = await services.queryCompletedLessons({ instruction, language, limit, offset });
      } catch (error) {
        return [];
      }

      return response.lessons;
    },
    [instruction, language, services]
  );

  const { canLoadMore, data: lessons, loadMore, reset } = usePagination<any>({ load });

  useEffect(reset, [instruction, language]);

  return (
    <div className="flex flex-col gap-4">
      {lessons?.map((lesson) => (
        <LessonCard key={lesson.id} lesson={lesson} />
      ))}
      {canLoadMore && (
        <ProfileButton className="self-center" onClick={loadMore}>
          {t("profile.showMoreLessons")}
        </ProfileButton>
      )}
    </div>
  );
};

const UserProfile = function UserProfileComponent({ userID }: { userID: string }) {
  const [user, setUser] = useState<User | null>(null);
  const [statistics, setStatistics] = useState<UserStatistics | null>(null);

  const { services, user: apiUser } = useAPI();
  const courseContext = useCourseContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isOwnProfile = useMemo(() => apiUser?.id === userID, [apiUser, userID]);

  useEffect(() => {
    (async () => {
      let response;
      try {
        response = await services.queryUser({ userID });
      } catch (error) {
        navigate("/404");
        return;
      }

      if (!response.user) {
        navigate("/404");
        return;
      }

      setUser(response.user);
      setStatistics(response.statistics);
    })();
  }, [userID]);

  if (!user || !statistics) return <Loader />;

  return (
    <div className="flex flex-col flex-grow p-4 gap-2">
      <h1 className="text-3xl font-bold">{user.name}</h1>
      <div className="flex justify-between flex-wrap gap-2">
        <div className="text-sm text-gray-500 self-end">{t("profile.joined", { date: new Date(user.createdAt) })}</div>
        <div className="flex gap-1 items-end">
          {statistics.languages.map((lang) => (
            <HoverPopup
              caret={false}
              key={lang}
              popup={
                <div>{t("profile.languageFlag", { language: t(`common.language.${lang}`), name: user.name })}</div>
              }
            >
              <span className="cursor-pointer">
                <img className="w-8 rounded shadow" src={loadImage(languageCodeToFlag(lang as LanguageCode))} />
              </span>
            </HoverPopup>
          ))}
        </div>
      </div>
      <div className="flex gap-4 justify-start">
        {isOwnProfile ? (
          <>
            <ProfileButton onClick={() => navigate("/settings")}>
              <Cog6ToothIcon className="w-4 h-4" />
              {t("profile.settingsButton")}
            </ProfileButton>
            <ProfileButton onClick={() => navigate("/referral")}>
              <EnvelopeIcon className="w-4 h-4" />
              {t("profile.referralButton")}
            </ProfileButton>
          </>
        ) : (
          <></>
        )}
      </div>
      <Separator />
      <SectionTitle>{t("profile.statistics")}</SectionTitle>
      <div className="grid grid-cols-2 max-w-md w-full self-center gap-6">
        <Statistic
          description={t("profile.dayStreakDescription", { count: statistics.dayStreak, name: user.name })}
          icon={
            <FireIcon
              className={twMerge(
                classNames("h-6 w-6 mr-3", {
                  "text-purple-600": statistics.dayStreak >= 365,
                  "text-red-700": statistics.dayStreak < 365,
                  "text-orange-600": statistics.dayStreak < 100,
                  "text-gray-400": statistics.dayStreak < 1 || !statistics.completedExerciseToday,
                })
              )}
            />
          }
          title={t("profile.dayStreak")}
          value={statistics.dayStreak.toString()}
        />
        <Statistic
          description={t("profile.completedLessonsDescription", {
            count: statistics.completedLessonCount,
            name: user.name,
          })}
          icon={<BookOpenIcon className="h-6 w-6 text-gray-500 mr-3" />}
          title={t("profile.completedLessons")}
          value={statistics.completedLessonCount.toString()}
        />
      </div>
      <SectionTitle>{t("profile.activity")}</SectionTitle>
      <div className="relative flex flex-col gap-4 w-full max-w-md self-center">
        <CalendarHeatmap
          classForValue={(value: any) => (value ? "color-scale-1" : "color-empty")}
          endDate={new Date()}
          gutterSize={2}
          monthLabels={t("common.months", { returnObjects: true }).map((month: string) => month.slice(0, 3))}
          showMonthLabels
          showWeekdayLabels
          startDate={new Date(new Date().setMonth(new Date().getMonth() - 3))}
          values={statistics.activeDays.map((date: Date) => ({ date, count: 1 }))}
          weekdayLabels={t("common.weekdays", { returnObjects: true }).map((weekday: string) => weekday.slice(0, 2))}
        />
        <div className="absolute flex justify-center gap-8 w-max bottom-4 left-1/2 -translate-x-1/2 text-slate-500">
          <span className="flex flex-row items-center gap-2">
            <div className="w-4 h-4 bg-[#fb923c] content" />
            active
          </span>
          <span className="flex flex-row items-center gap-2">
            <div className="w-4 h-4 bg-[#ddd] content" />
            not active
          </span>
        </div>
      </div>
      {isOwnProfile && (
        <>
          <SectionTitle>{t("profile.completedLessons", { language: courseContext.language })}</SectionTitle>
          <CompletedLessons instruction={courseContext.instruction} language={courseContext.language} />
        </>
      )}
    </div>
  );
};

export default UserProfile;
