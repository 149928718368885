import { AxiosError } from "axios";

import i18next from "utils/i18next";

export type APIError = { error: AxiosError<any, any>; isAPIError: boolean; message?: string };

const handleAPIError = (error: AxiosError<any, any>) => {
  let apiError: APIError = { error, isAPIError: true };

  if (!error.response) {
    apiError = { ...apiError, message: i18next.t("error.network") };
  } else if (error.response.data.code === 303) {
    window.location.href = "/signup-to-continue";
  }

  return apiError;
};

export default handleAPIError;
