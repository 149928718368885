import React from "react";
import HoverPopup from "components/HoverPopup";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

interface HintProps {
  hint: string;
  className?: string;
}

const Hint: React.FC<HintProps> = function Hint({ hint, className }) {
  const { t } = useTranslation();
  return (
    <HoverPopup
      caret={false}
      className={className}
      popup={<div className="w-96 leading-normal">{hint}</div>}
      popupMargin={20}
    >
      <span className="text-slate-600 cursor-pointer text-sm font-semibold leading-tight flex items-center gap-2">
        <span>{t("lesson.exercise.chatBubble.hint")}</span> <ChevronDownIcon className="w-3 h-3 !stroke-[2.5]" />
      </span>
    </HoverPopup>
  );
};

export default Hint;
