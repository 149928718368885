import React, { useCallback, useEffect, useState } from "react";
import SpeechRecognition from "react-speech-recognition";

import decodeJWT from "utils/decodeJWT";
import APIContext, { APIContextType, APIUser } from "./APIContext";
import { createUser as createUserService, reauth as reauthService } from "./routes/user";
import { AccessTokenPayload } from "./types";

type APIProviderProps = { children: React.ReactNode };

const applyAzureSpeechServicesPolyfill = (credentials: AccessTokenPayload["azureSpeechServiceCredentials"]) => {
  const polyfill = (window as any).WebSpeechCognitiveServices;

  if (!polyfill?.create) return false;

  try {
    SpeechRecognition.applyPolyfill(polyfill.create({ credentials }).SpeechRecognition);
  } catch (error) {
    return false;
  }

  return true;
};

const APIProvider: React.FC<APIProviderProps> = function APIProviderComponent({ children }) {
  const [reauthTimeout, setReauthTimeout] = useState<NodeJS.Timeout | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [user, setUser] = useState<APIUser | null>(null);

  const signout = () => {
    localStorage.removeItem("refreshToken");
    setAccessToken(null);

    window.location.reload();
  };

  const context: APIContextType = {
    accessToken,
    initialized,
    reauth: () => Promise.resolve(true),
    services: {},
    setAccessToken,
    signout,
    user,
  };

  const reauth = useCallback(async () => {
    const token = localStorage.getItem("refreshToken");

    if (!token) return false;

    try {
      await reauthService(context, { refreshToken: token });
    } catch (error) {
      signout();
      return false;
    }

    return true;
  }, [context]);

  // Reauth on mount if refreshToken is present.
  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("refreshToken");

      if (token) {
        if (!(await reauth())) setInitialized(true);

        return;
      }

      try {
        await createUserService(context, {});
      } catch (error) {
        setInitialized(true);
      }
    })();
  }, []);

  // Set initialized to true only after accessToken is set by reauth.
  // Fetch user data from accessToken.
  // Refresh access token every 10 minutes.
  useEffect(() => {
    if (!accessToken) return () => {};

    let tokenData;
    try {
      tokenData = decodeJWT<AccessTokenPayload>(accessToken);
    } catch (error) {
      setAccessToken(null);
      return () => {};
    }

    // Extract token data.
    setUser(tokenData.user);
    if (tokenData.azureSpeechServiceCredentials)
      applyAzureSpeechServicesPolyfill(tokenData.azureSpeechServiceCredentials);

    // Set timeout to refresh access after 10 minutes.
    if (reauthTimeout) clearTimeout(reauthTimeout);

    const timeout = setTimeout(reauth, 600000);
    setReauthTimeout(timeout);

    if (!initialized) setInitialized(true);

    return () => {
      if (reauthTimeout) clearTimeout(reauthTimeout);

      setUser(null);
    };
  }, [accessToken]);

  const fullContext = { ...context, reauth };

  return <APIContext.Provider value={fullContext}>{children}</APIContext.Provider>;
};

export default APIProvider;
