import axios from "axios";

import { APIContextType } from "./APIContext";
import { API_BASE_URI } from "./config";
import handleAPIError from "./handleAPIError";
import { reauth } from "./routes/user";

const defaultHeaders = {};

export type RequestOptions = {
  data?: object;
  headers?: object;
  method?: string;
  params?: object;
  reattempt?: boolean;
  unauthenticated?: boolean;
};

/**
 * Makes an API request and returns the data returned by the API.
 * @param context The API context.
 * @param route A string specifying the route to make a request to.
 * @param options An object of options.
 * @param [options.data] The data to pass in the body of the request.
 * @param [options.headers] Headers to set on the request.
 * @param [options.method] The HTTP method for the request.
 * @param [options.params] The data to pass in the GET query of the request.
 * @param [options.reattempt] Whether to reattempt the request if the access token is invalid.
 * @param [options.unauthenticated] Whether the request should be made without an access token.
 * @returns The data returned by the API.
 * @throws An error if the request fails.
 */
const makeRequest = async function makeAPIRequest(
  context: APIContextType,
  route: string,
  {
    data = undefined,
    headers = {},
    method = "GET",
    params = undefined,
    reattempt = true,
    unauthenticated = false,
  }: RequestOptions
): Promise<any> {
  const { accessToken } = context;

  const trimmedRoute = route.replace(/^\//, "");
  const url = `${API_BASE_URI}/${trimmedRoute}`;

  let augmentedHeaders = { ...defaultHeaders, ...headers };

  if (!unauthenticated) augmentedHeaders = { ...augmentedHeaders, Authorization: `Bearer ${accessToken}` };

  const options = { data, params, headers: augmentedHeaders, method, url };

  let response;
  try {
    response = await axios(options);
  } catch (error: any) {
    if (error.response.status === 401 && reattempt) {
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) throw error;

      localStorage.removeItem("accessToken");

      try {
        context.accessToken = (await reauth(context, { refreshToken })).accessToken;
      } catch (_) {
        throw error;
      }

      return makeRequest(context, route, { data, headers, method, params, reattempt: false });
    }

    throw handleAPIError(error);
  }

  return response.data;
};

export default makeRequest;
