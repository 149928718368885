import React, { useState, useCallback } from "react";
import { AssistantBubble } from "./shared/DialogueExercise";
import { InputAndControls } from "./shared/InputAndControls";
import { useAPI } from "services/api";
import handleError from "utils/handleError";
import { useExercise } from ".";
import { useLesson } from "../LessonContext";
import { useTranslation } from "react-i18next";
import { ChatBubble, CompleteExerciseButton, ScrollChat } from "./shared";
import twm from "utils/twm";
import Medium from "components/Lesson/Medium";
import { CompleteExerciseButtonButton } from "./shared/CompleteExerciseButton";
import { resetBlankIndex } from "utils/pretreatText";

const FillInExercise: React.FC = function FillInExerciseComponent() {
  const exercise = useExercise();
  const { services } = useAPI();
  const { vocabularyView, onPreComplete } = useLesson();
  const [complete, setComplete] = useState(false);
  const [highlightEmpty, sethighlightEmpty] = useState(false);
  resetBlankIndex();
  const { t } = useTranslation();

  const submitAnswers = useCallback(() => {
    exercise.pushState((prev: any) => ({ ...prev, preSubmit: false }));
    exercise.pushState((prev: any) => ({ ...prev, preSubmit: true }));

    let answers = Array.from({ length: exercise.data.answers.length }) as string[][];
    answers = answers.map((_, index) => {
      const subarray = Array.from({ length: exercise.data.answers[index].length }) as string[];
      return subarray.map((_1, index2) => exercise.state?.answers?.[index]?.[index2] ?? "");
    });

    const completed =
      !!answers?.length && answers.every((answer: string[]) => answer.every((a: string) => a.trim().length > 0));
    sethighlightEmpty(!completed);
    if (completed) {
      services
        .completeExercise({ exerciseID: exercise.id })
        .then(() => {
          onPreComplete();
          setComplete(true);
        })
        .catch((error: any) => {
          handleError(error);
        });
    }
  }, [services, exercise]);

  return (
    <>
      <ScrollChat className={twm({ hidden: vocabularyView })}>
        <div className="h-1"></div>
        {exercise.directions && (
          <AssistantBubble
            content={exercise.directions}
            header={t("lesson.exercise.directions")}
            className="bg-blue-100 w-full"
            disablePlay={true}
            plain={exercise.data?.plainDescription}
            disableTranslate={exercise.data?.plainDescription}
          ></AssistantBubble>
        )}
        <ChatBubble className="bg-slate-50 w-full">
          <Medium speechSynthID="medium" {...exercise.medium} />
        </ChatBubble>
        <AssistantBubble
          content={exercise.data.text}
          plain={exercise.data?.plain}
          speakerID={exercise.data.speakerID}
          hint={exercise.data?.hint}
          disableTranslate={exercise.data?.plain}
          disablePlay={exercise.data?.plain}
          className={twm({ "font-bold bg-slate-100": exercise.data?.plain })}
        />
        {highlightEmpty && <div className="text-red-500 py-1">{t("lesson.exercise.chatBubble.fillIn.fillAll")}</div>}
        <div className="h-10">&nbsp;</div>
      </ScrollChat>
      <InputAndControls>
        {!complete && (
          <CompleteExerciseButtonButton
            continueText={t("lesson.exercise.chatBubble.fillIn.submit")}
            handleClick={submitAnswers}
            isLastExercise={false}
            className="bg-blue-600 hover:bg-blue-700"
          ></CompleteExerciseButtonButton>
        )}
        {complete && (
          <CompleteExerciseButton
            onClick={() => {
              exercise.pushState({ preSubmit: false });
              exercise.onComplete();
            }}
          />
        )}
      </InputAndControls>
    </>
  );
};

export default FillInExercise;
