import React, { createContext } from "react";

const ContainerContext = createContext<React.RefObject<HTMLDivElement> | null>(null);

const useContainer = () => {
  const context = React.useContext(ContainerContext);

  if (!context) {
    throw new Error("useContainer must be used within a ContainerProvider");
  }

  return context;
};

type ContainerProviderProps = { children: React.ReactNode; containerRef: React.RefObject<HTMLDivElement> };

const ContainerProvider: React.FC<ContainerProviderProps> = ({ children, containerRef }) => (
  <ContainerContext.Provider value={containerRef}>{children}</ContainerContext.Provider>
);

export { ContainerContext, ContainerProvider, useContainer };
