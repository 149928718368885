import { useTranslation } from "react-i18next";
import { FaceFrownIcon } from "@heroicons/react/24/outline";

import Navigation from "./Navigation";

const NotFound = function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col grow">
      <Navigation />
      <div className="flex flex-col grow justify-center gap-8 p-4">
        <FaceFrownIcon className="w-24 h-24 text-black self-center" />
        <h1 className="text-lg sm:text-xl font-semibold text-center">{t("error.pageNotFound")}</h1>
      </div>
    </div>
  );
};

export default NotFound;
