import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import useSpeechSynth, { SpeechSynthProvider } from "components/useSpeechSynth";
import Description from "./Description";
import SectionTitle from "./SectionTitle";
import Title from "./Title";
import Phrase from "./Phrase";
import Vocabulary from "./Vocabulary";
import { ForwardIcon, PlayIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { MediumTypeProps } from "../types";
import Table from "./Table";
import Image from "./Image";

interface CompositeBoxProps extends MediumTypeProps {
  data: {
    type: string;
    id: string;
    instructionSpeakerID: string;
    languageSpeakerID: string;
    assembly: {
      type: "title" | "description" | "sectionTitle" | "vocabulary" | "phrase" | "image" | "table";
      content: {
        text?: string;
        translation?: string;
        vocabulary?: string;
        src?: string;
      };
    }[];
  };
}

const componentAssemblyMap: any = {
  title: Title,
  description: Description,
  sectionTitle: SectionTitle,
  vocabulary: Vocabulary,
  phrase: Phrase,
  table: Table,
  image: Image,
};

const CompositeBox: React.FC<CompositeBoxProps> = function CompositeBoxComponent({
  data: { assembly, instructionSpeakerID, languageSpeakerID },
  speechSynthID: mediumSpeechSynthID,
}) {
  const [speechSynthID, setSpeechSynthID] = React.useState<string | null>(null);
  const [speechLineIndex, setSpeechLineIndex] = useState<number>(Number.MAX_SAFE_INTEGER);
  const linesCount = useMemo(
    () => assembly.filter((c) => !["title", "sectionTitle"].includes(c.type)).length,
    [assembly],
  );

  const { onSpeechSynthComplete } = useSpeechSynth({
    interrupt: () => {},
    speechSynthID: mediumSpeechSynthID,
    trigger: () => {},
  });

  const handleSpeechSynthComplete = useCallback(() => {
    setSpeechSynthID(null);
    setSpeechLineIndex(Number.MAX_SAFE_INTEGER);
  }, []);

  useEffect(() => {
    if (speechLineIndex >= linesCount) {
      onSpeechSynthComplete();
      return;
    }

    setSpeechSynthID(`${speechLineIndex}`);
  }, [speechLineIndex]);

  let runningIndex = 0;
  return (
    <SpeechSynthProvider activeID={speechSynthID} onComplete={handleSpeechSynthComplete}>
      <div className="flex flex-col">
        {assembly.map((c: any, index) => {
          const C = componentAssemblyMap[c.type];
          if (!["image", "table", "title", "sectionTitle"].includes(c.type) && !c.content?.plain) runningIndex += 1;
          return (
            <Fragment key={index}>
              <C
                key={index}
                content={c.content}
                speechSynthID={
                  ["image", "table", "title", "sectionTitle"].includes(c.type) ? undefined : `${runningIndex - 1}`
                }
                onPlayClick={(id: null | string) => {
                  if (speechLineIndex < linesCount) return;
                  if (speechSynthID !== null) setSpeechSynthID(null);
                  setSpeechSynthID(id);
                }}
                disablePlay={speechLineIndex < linesCount}
                instructionSpeakerID={instructionSpeakerID}
                languageSpeakerID={languageSpeakerID}
                pointerEvents={speechLineIndex >= runningIndex - 1}
                className={classNames({
                  "opacity-100": speechLineIndex >= runningIndex - 1,
                  "opacity-50 !pointer-events-none [&>div>div>span>span]:!pointer-events-none [&>div>div>span>span>span]:!pointer-events-none [&>div>span>span>span>span]:!pointer-events-none [&>div>div>span>span>span>span]:!pointer-events-none [&>div>span>span]:!pointer-events-none [&>div>span>span>span]:!pointer-events-none":
                    speechLineIndex < runningIndex - 1,
                })}
              />
              {["vocabulary", "phrase"].includes(c.type) &&
                ["description", "sectionTitle"].includes(assembly[index + 1]?.type) && <div className="h-1"></div>}
            </Fragment>
          );
        })}
      </div>
    </SpeechSynthProvider>
  );
};

export default CompositeBox;
