import { Fragment, useMemo } from "react";

import classNames from "classnames";
import { SpeechSynthProps } from "components/useSpeechSynth";

import { twMerge } from "tailwind-merge";

type PlainTextProps = { className?: string; text: string; showUntilIndex?: number } & SpeechSynthProps;

// Displays selectable text including control over words
const PlainText = function PlainTextComponent({ className, text, showUntilIndex = Number.MAX_VALUE }: PlainTextProps) {
  const shatteredText = useMemo(() => text.split(/(\s)/gu), [text]);

  if (!text) return null;

  return (
    <span className={twMerge(className, "select-none")}>
      {shatteredText.map((group, index) => {
        if (/^\s$/gu.test(group)) return <Fragment key={index}>{group}</Fragment>;

        return (
          <span
            key={index}
            className={classNames({
              "opacity-20": index > showUntilIndex + 1,
              "opacity-100": index <= showUntilIndex + 1,
            })}
          >
            {group}
          </span>
        );
      })}
    </span>
  );
};

export default PlainText;
