import { ArrowRightOnRectangleIcon, KeyIcon, TrashIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, TextInput } from "components/form";
import Navigation from "components/UserInterface/Navigation";
import { useAPI } from "services/api";
import DeleteAccountForm from "./DeleteAccountForm";
import EmailForm from "./EmailForm";
import PasswordForm from "./PasswordForm";
import UserForm from "./UserForm";

const HiddenForm = ({
  children,
  form,
  title,
}: {
  children?: React.ReactNode;
  form: React.ReactNode;
  title: string | React.ReactNode;
}) => {
  const [hidden, setHidden] = useState(true);

  // Hook onComplete to hide the form after it's been submitted
  const modifiedForm = React.cloneElement(form as React.ReactElement, {
    onComplete: () => {
      setHidden(true);
      if ((form as any).props.onComplete) (form as any).props.onComplete();
    },
    onCancel: () => {
      setHidden(true);
      if ((form as any).props.onCancel) (form as any).props.onCancel();
    },
  });

  return hidden ? (
    <>
      {children}
      <Button className="bg-slate-200 text-black hover:bg-slate-300" onClick={() => setHidden(false)}>
        {title}
      </Button>
    </>
  ) : (
    modifiedForm
  );
};

const Separator = () => <div className="h-[2px] bg-slate-200 w-full" />;

const SectionTitle = ({ children }: { children: React.ReactNode }) => (
  <h2 className="text-center text-lg font-semibold">{children}</h2>
);

const Settings = function SettingsPage() {
  const { signout, user } = useAPI();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (user?.email) return;

    navigate("/signup");
  }, [user]);

  if (!user?.email) return null;

  return (
    <div className="flex flex-col flex-grow items-stretch">
      <Navigation />
      <div className="flex flex-col w-full max-w-md self-center p-4 gap-4">
        <SectionTitle>{t("settings.title.user")}</SectionTitle>
        <UserForm user={user} />
        <HiddenForm form={<EmailForm user={user} />} title={t("settings.showEmailForm")}>
          <TextInput disabled label={t("signup.label.email")} type="email" value={user.email} />
        </HiddenForm>
        <Separator />
        <SectionTitle>{t("settings.title.danger")}</SectionTitle>
        <Button className="bg-slate-200 text-black hover:bg-slate-300 gap-2" onClick={() => signout()}>
          <ArrowRightOnRectangleIcon className="w-4 h-4" />
          {t("settings.signoutButton")}
        </Button>
        <HiddenForm
          form={<PasswordForm />}
          title={
            <div className="flex gap-2 items-center">
              <KeyIcon className="w-4 h-4" />
              {t("settings.showPasswordForm")}
            </div>
          }
        />
        <HiddenForm
          form={<DeleteAccountForm onComplete={() => navigate("/signup")} />}
          title={
            <div className="flex gap-2 items-center">
              <TrashIcon className="w-4 h-4" />
              {t("settings.deleteAccount")}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Settings;
