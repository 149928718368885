import { XMarkIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Button, CheckBoxInput, FormErrors, TextInput, useForm, Validations } from "components/form";
import { ConfirmPopup } from "components/Popup";
import { useCourseContext } from "components/UserInterface/CourseContext";
import { useAPI } from "services/api";
import { VocabularyCard } from "services/api/types";
import handleError from "utils/handleError";
import Navigation from "../Navigation";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import twm from "utils/twm";

const VocabForm = function VocabFormComponent() {
  const navigate = useNavigate();
  const { vocabularyCardID } = useParams();
  const { services } = useAPI();
  const [card, setCard] = useState<VocabularyCard | undefined>();
  const [doubleSided, setDoubleSided] = useState(false);
  const [text, setText] = useState(card?.text || "");
  const [translation, setTranslation] = useState(card?.translation || "");
  const { instruction, language } = useCourseContext();
  const [expandOptions, setExpandOptions] = useState(false);
  const textRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const fields = useMemo(() => ({ text, translation }), [text, translation]);
  const validations = [] as Validations;

  const { dirty, formErrors, hookSubmit, resetInitialValues, submitting } = useForm({ fields, validations });

  useEffect(() => {
    if (!vocabularyCardID) {
      setCard(undefined);
      return;
    }

    services.queryVocabularyCard({ vocabularyCardID }).then(setCard).catch(handleError);
  }, [vocabularyCardID]);

  useEffect(() => {
    const values = { text: card?.text || "", translation: card?.translation || "" };

    setText(values.text);
    setTranslation(values.translation);
    resetInitialValues(values);
  }, [card]);

  const clearForm = useCallback(() => {
    setDoubleSided(false);
    setText("");
    setTranslation("");
    resetInitialValues();
    textRef.current?.focus();
  }, []);

  const handleSubmit = hookSubmit(
    useCallback(async () => {
      if (card) {
        await services.updateVocabularyCard({ vocabularyCardID: card.id, ...fields });
        resetInitialValues();
        return;
      }

      await services.createVocabularyCard({ instruction, language, ...fields });
      if (doubleSided)
        await services.createVocabularyCard({ instruction, language, text: translation, translation: text });

      clearForm();
    }, [card, fields, services])
  );

  const handleDelete = useCallback(async () => {
    if (!card) return;

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPopup
          icon={<div className="relative flex grow gap-4 items-center"></div>}
          onCancel={onClose}
          onConfirm={async () => {
            try {
              await services.deleteVocabularyCard({ vocabularyCardID: card.id });
              navigate("/vocab/stack");
            } catch (error) {
              handleError(error);
            } finally {
              onClose();
            }
          }}
        >
          {t("vocab.form.deleteConfirmMessage")}
        </ConfirmPopup>
      ),
    });
  }, [card, services]);

  const handleSetDue = useCallback(async () => {
    if (!card) return;

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPopup
          icon={<div className="relative flex grow gap-4 items-center"></div>}
          onCancel={onClose}
          onConfirm={async () => {
            try {
              await services.updateVocabularyCard({ vocabularyCardID: card.id, dueAt: new Date().toISOString() });
              resetInitialValues();
            } catch (error) {
              handleError(error);
            } finally {
              onClose();
            }
          }}
        >
          {t("vocab.form.setDueConfirmMessage")}
        </ConfirmPopup>
      ),
    });
  }, [card, services]);

  return (
    <div className="flex flex-col">
      <Navigation />
      <div className="flex flex-col gap-4 p-4">
        <div className="flex flex-row justify-end">
          <button
            className="cursor-pointer text-slate-600 hover:text-red-600 transition-colors"
            onClick={() => navigate("/vocab/stack")}
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <form className="flex flex-col flex-grow gap-8 max-w-md w-full self-center" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4">
            <TextInput
              autofocus
              errors={formErrors.getErrors("text")}
              label={t("vocab.form.textLabel")}
              type="text"
              value={text}
              onChange={setText}
              ref={textRef}
            />
            <TextInput
              errors={formErrors.getErrors("translation")}
              label={t("vocab.form.translationLabel")}
              type="text"
              value={translation}
              onChange={setTranslation}
            />
            {!card && (
              <CheckBoxInput
                errors={formErrors.getErrors("doubleSided")}
                label={t("vocab.form.doubleSidedLabel")}
                checked={doubleSided}
                onChange={setDoubleSided}
              />
            )}
          </div>
          <div className="flex flex-row gap-4">
            <Button className="grow" type="submit" disabled={!formErrors.validate() || !dirty} loading={submitting}>
              {card ? t("vocab.form.updateButtonLabel") : t("vocab.form.createButtonLabel")}
            </Button>
            {card && (
              <Button
                className="bg-white text-black hover:bg-white hover:text-black border border-slate-400 hover:border-black transition-colors"
                onClick={() => setExpandOptions(!expandOptions)}
              >
                <EllipsisHorizontalIcon className="w-6 h-6" />
              </Button>
            )}
          </div>
          {card && (
            <div className={twm("flex flex-col", { hidden: !expandOptions })}>
              <div className="flex flex-col border border-slate-400">
                <Button
                  className="bg-transparent text-black hover:text-black hover:bg-slate-50"
                  type="button"
                  loading={submitting}
                  onClick={handleSetDue}
                >
                  Review now
                </Button>
                <Button
                  className="bg-transparent text-black bg-red-200 hover:bg-red-500 hover:text-white"
                  type="button"
                  loading={submitting}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </div>
            </div>
          )}
          <FormErrors context={formErrors} exclude={["text", "translation"]} />
        </form>
      </div>
    </div>
  );
};

export default VocabForm;
