import classNames from "classnames";
import React from "react";

type ListSelectOption = {
  disabled?: boolean;
  label: string;
  selected?: boolean;
  value: any;
};

type ListSelectProps = {
  onChange: (value: any) => void;
  options: ListSelectOption[];
};

const ListSelect: React.FC<ListSelectProps> = function ListSelectComponent({ onChange, options }) {
  return (
    <div className="w-full max-w-md mx-auto">
      <ul>
        {options.map(({ disabled, label, selected, value }) => (
          <li
            className={classNames("px-3 py-2 select-none block w-full border rounded-md my-2 font-semibold", {
              "border-slate-200 text-slate-200": disabled,
              "border-blue-500 text-blue-500 hover:bg-gray-100 cursor-pointer": !disabled && selected,
              "border-slate-300 text-slate-500 hover:bg-gray-100 cursor-pointer": !disabled && !selected,
            })}
            key={`${value}`}
            onClick={() => !disabled && onChange(value)}
          >
            {label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListSelect;
