import React from "react";

interface SectionTitleProps {
  content: {
    text: string;
  };
}

const SectionTitle: React.FC<SectionTitleProps> = function SectionTitleComponent({ content: { text } }) {
  return <h2 className="font-semibold min-h-[16px]">{text}</h2>;
};

export default SectionTitle;
