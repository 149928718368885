import React, { createContext, useCallback, useContext, useState } from "react";
import useAPI from "./useAPI";

type TranslationType = { translations: string[]; inflections: any };

type Translations = { [from: string]: { [to: string]: { [word: string]: TranslationType } } };

interface TranslationContextType {
  translations: Translations;
  lookupWord: (key: string) => Promise<string>;
  translatePhrase: ({ phrase, direction }: { phrase: string; direction?: -1 | 1 | undefined }) => Promise<string>;
}

const TranslationContext = createContext<TranslationContextType | Record<string, object>>({});

type TranslationContextProps = { children: React.ReactNode; from: string; to: string };

const TranslationProvider = function TranslationProviderComponent({ children, from, to }: TranslationContextProps) {
  const [translations, setTranslations] = useState<Translations>({});
  const { services } = useAPI();

  const addTranslation = useCallback(
    ({ phrase, translation }: { phrase: string; translation: TranslationType }) => {
      setTranslations((prev: any) => {
        const newTranslations = { ...prev };

        if (!(from in newTranslations)) newTranslations[from] = {};
        if (!(to in newTranslations[from])) newTranslations[from][to] = {};
        newTranslations[from][to][phrase] = translation;

        return newTranslations;
      });
    },
    [from, setTranslations, to]
  );

  const fetchPhraseTranslation = useCallback(
    async ({ phrase, fromL, toL }: { phrase: string; fromL: string; toL: string }) => {
      const translation = await services.translatePhrase({ from: fromL, to: toL, phrase });

      addTranslation({ phrase, translation });

      return translation;
    },
    [addTranslation, services]
  );

  const translatePhrase = useCallback(
    async ({ phrase, direction }: { phrase: string; direction: 1 | -1 | undefined }) => {
      let [fromL, toL] = [from, to];
      if (direction === -1) [fromL, toL] = [to, from];
      if (fromL in translations && toL in translations[fromL] && phrase in translations[fromL][toL])
        return translations[fromL][toL][phrase].translations[0];

      return (await fetchPhraseTranslation({ fromL, toL, phrase })).translations[0];
    },
    [from, to, translations]
  );

  // right now: only translation
  const fetchWordLookup = useCallback(
    async ({ word }: { word: string }) => {
      const translation = await services.translateWord({ from, to, word });

      addTranslation({ phrase: word, translation });

      return translation;
    },
    [addTranslation, services]
  );

  const lookupWord = useCallback(
    async (word: string) => {
      if (from in translations && to in translations[from] && word in translations[from][to])
        return translations[from][to][word].translations[0];

      return (await fetchWordLookup({ word })).translations[0];
    },
    [from, to, translations]
  );

  const context = { lookupWord, translatePhrase, translations };

  return <TranslationContext.Provider value={context}>{children}</TranslationContext.Provider>;
};

function useTranslations() {
  const context = useContext(TranslationContext) as TranslationContextType;

  return context as TranslationContextType;
}

export default TranslationContext;
export { TranslationProvider, useTranslations };
