import { useCallback, useEffect, useState } from "react";

type LoadFunction<T> = (params: { limit: number; offset: number }) => Promise<T[]>;

type UsePagination<T> = {
  canLoadMore: boolean;
  clear: () => void;
  data: T[];
  isLoading: boolean;
  isResetting: boolean;
  loadMore: () => void;
  reset: () => void;
};

type UsePaginationOptions<T> = {
  limit?: number;
  load: LoadFunction<T>;
  loadInitially?: boolean;
};

const usePagination = <T,>({
  limit = 10,
  load: load_,
  loadInitially = true,
}: UsePaginationOptions<T>): UsePagination<T> => {
  const [isLoading, setIsLoading] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [data, setData] = useState<T[]>([]);
  const [offset, setOffset] = useState(0);

  const load = useCallback(async () => {
    setIsLoading(true);
    const result = await load_({ limit, offset });
    setIsLoading(false);

    return result;
  }, [limit, load_, offset, setIsLoading]);

  const reset = () => {
    setIsResetting(true);
  };

  const clear = () => {
    setData([]);
    setOffset(0);
    setCanLoadMore(false);
  };

  const loadMore = () => setOffset((current) => current + limit);

  useEffect(() => {
    if (!loadInitially) return;
    (async () => {
      const initialData = await load();
      setData(initialData);
      setCanLoadMore(initialData.length === limit);
    })();
  }, []);

  useEffect(() => {
    if (offset === 0) return;

    (async () => {
      const additionalData = await load();

      setData((current) => [...current, ...additionalData]);
      setCanLoadMore(additionalData.length === limit);
    })();
  }, [offset]);

  useEffect(() => {
    if (!isResetting) return;

    if (offset !== 0) {
      setOffset(0);
      return;
    }

    (async () => {
      const newData = await load();

      setData(newData);
      setIsResetting(false);
      setCanLoadMore(newData.length === limit);
    })();
  }, [isResetting, offset]);

  return { canLoadMore, clear, data, isLoading, isResetting, loadMore, reset };
};

export default usePagination;
