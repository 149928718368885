import { Fragment, useCallback, useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import { useAPI } from "services/api";
import { useLesson } from "components/Lesson/LessonContext";
import twm from "utils/twm";
import { useExercise } from ".";
import { ChatBubble, CompleteExerciseButton, ScrollChat } from "./shared";
import { AssistantBubble } from "./shared/DialogueExercise";
import { languageToFullCode } from "./shared/Inputs/TextInput";
import { InputAndControls } from "./shared/InputAndControls";
import { MicrophoneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import handleError from "utils/handleError";
import Medium from "../Medium";
import { useTranslation } from "react-i18next";

interface ListenBubbleProps {
  active?: boolean;
  className?: string;
  disabled?: boolean;
  onClick: () => void;
}

const ListenBubble = ({ active, className, disabled = false, onClick }: ListenBubbleProps) => {
  const Icon = active ? XMarkIcon : MicrophoneIcon;

  return (
    <div className={twm("bg-yellow-50 shadow-lg rounded-full p-2 border", className)}>
      <div className={"relative flex flex-col justify-center items-center"}>
        <button
          className={twm(
            "flex justify-center items-center w-20 h-20 sm:w-32 sm:h-32 bg-green-500 hover:bg-green-600 rounded-full disabled:hover:cursor-default disabled:text-gray-400 disabled:bg-gray-300 z-10 hover:cursor-pointer transition-colors",
            { "bg-red-500 hover:bg-red-600": active },
          )}
          onClick={onClick}
          disabled={disabled}
        >
          <Icon className="w-8 h-8 text-white stroke-2" />
        </button>
        <div
          className={twm("absolute w-20 h-20 sm:w-32 sm:h-32  bg-red-300 rounded-full z-0", {
            "animate-microphone": active,
            hidden: disabled,
          })}
        />
      </div>
    </div>
  );
};

const speechRepetitionCount = 2;

const SpeechExercise = function SpeechExerciseComponent() {
  const exercise = useExercise();
  const {
    data: { followup },
    directions,
    id: exerciseID,
  } = exercise;
  const {
    onPreComplete,
    lesson: { language },
    vocabularyView,
    microphoneAvailable,
  } = useLesson();

  const [complete, setComplete] = useState(false);
  const [text, setText] = useState("");
  const [textList, setTextList] = useState<string[]>([]);
  const {
    browserSupportsSpeechRecognition: browserSupport,
    isMicrophoneAvailable,
    listening: listeningState,
    resetTranscript,
    transcript,
  } = useSpeechRecognition({});
  const { t } = useTranslation();
  const { services } = useAPI();
  const [append] = useState<string>("");
  let listening = listeningState;
  let browserSupportsSpeechRecognition = browserSupport;
  if (!microphoneAvailable) {
    listening = false;
    browserSupportsSpeechRecognition = false;
  }

  // if (!("webkitSpeechRecognition" in window || "SpeechRecognition" in window)) {
  //  listening = false;
  //  browserSupportsSpeechRecognition = false;
  // }

  const completeExercise = useCallback(
    () =>
      services
        .completeExercise({ exerciseID })
        .then(() => {
          onPreComplete();
          setComplete(true);
        })
        .catch((error: any) => {
          handleError(error);
        }),
    [exerciseID, onPreComplete, services],
  );

  const startListening = useCallback(() => {
    setText("");
    resetTranscript();

    if (!microphoneAvailable) return;

    try {
      SpeechRecognition.startListening({ language: languageToFullCode[language] });
    } catch (error) {
      handleError(error);
    }
  }, [language, resetTranscript, setText, microphoneAvailable]);

  const stopListening = useCallback(() => {
    if (!microphoneAvailable) return;
    try {
      SpeechRecognition.abortListening();
    } catch (error) {
      handleError(error);
    }
  }, [microphoneAvailable]);

  const toggleListening = useCallback(() => {
    if (listening) {
      stopListening();
    } else startListening();
  }, [listening, startListening, stopListening]);

  useEffect(() => {
    resetTranscript();

    return stopListening; // Stop listening on unmount.
  }, []);

  useEffect(() => {
    setText(transcript || "");
  }, [transcript]);

  useEffect(() => {
    if (listening) {
      setText("");
    } else {
      if (text && text.length > 0) setTextList((list) => [...list, text]);
      setText("");
      stopListening();
    }
  }, [listening]);

  useEffect(() => {
    if (textList.length < speechRepetitionCount) return;

    completeExercise();
  }, [textList]);

  const speechUnavailable = !isMicrophoneAvailable || !browserSupportsSpeechRecognition;

  return (
    <>
      <ScrollChat className={twm("grow justify-between relative", { hidden: vocabularyView })}>
        <div className="h-1"></div>
        {directions && (
          <AssistantBubble
            content={directions}
            className="bg-blue-100 w-full"
            header={t("lesson.exercise.directions")}
            disablePlay={true}
            disableTranslate={exercise.data?.plainDescription}
            plain={exercise.data?.plainDescription}
          ></AssistantBubble>
        )}
        <ChatBubble className="bg-slate-50 w-full">
          <Medium {...exercise.medium} />
        </ChatBubble>
        <AssistantBubble
          content={exercise.data.text}
          speakerID={exercise.data.speakerID}
          plain={exercise.data?.plain}
          disablePlay={exercise.data?.plain}
          disableTranslate={exercise.data?.plain}
          className={twm({ "font-bold bg-slate-100": exercise.data?.plain })}
        ></AssistantBubble>
        {Array.from({ length: speechRepetitionCount }).map((_, i) => (
          <Fragment key={i}>
            <ChatBubble
              className={twm("flex flex-row gap-2 bg-green-100 self-end", {
                hidden: textList.length < i,
                "bg-green-200": listening,
              })}
            >
              <div className={twm("italic text-slate-500", { "text-green-700 font-bold not-italic": listening })}>
                {listening ? t("lesson.exercise.speakNow") : t("lesson.exercise.speakOutputPrompt")}
              </div>
              <div>{textList.length > i ? textList[i] : text + append}</div>
            </ChatBubble>

            {i < speechRepetitionCount - 1 && (
              <AssistantBubble
                className={twm({ hidden: textList.length <= i })}
                content={followup}
                disablePlay={true}
              ></AssistantBubble>
            )}
          </Fragment>
        ))}

        <div className="h-20 block shrink-0">&nbsp;</div>
      </ScrollChat>
      <InputAndControls>
        {!complete && (
          <>
            <button
              className={twm(
                "px-2 py-1 text-sm bg-white text-slate-500 self-start rounded border border-black hover:text-black",
              )}
              onClick={completeExercise}
            >
              {t("common.cannotSpeak")}
            </button>
            <ListenBubble
              className={twm("absolute left-1/2 bottom-3 transform -translate-x-1/2", {
                hidden: textList.length >= speechRepetitionCount,
              })}
              active={listening}
              disabled={speechUnavailable || textList.length > speechRepetitionCount}
              onClick={() => {
                toggleListening();
              }}
            />
          </>
        )}

        {complete && <CompleteExerciseButton />}
      </InputAndControls>
    </>
  );
};

export default SpeechExercise;
