import React, { useEffect, useMemo, useState } from "react";

import { loadSilence } from "data";

const AudioContext = React.createContext({ audio: new Audio(loadSilence()), initialized: false });

const AudioProvider = ({ children }: { children: React.ReactNode }) => {
  const [initialized, setInitialized] = useState(false);
  const audio = useMemo(() => new Audio(loadSilence()), []);

  useEffect(() => {
    const handleClick = async () => {
      await audio.play();
      audio.pause();

      audio.currentTime = 0;
      setInitialized(true);

      document.removeEventListener("click", handleClick);
    };

    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, [audio]);

  return <AudioContext.Provider value={{ audio, initialized }}>{children}</AudioContext.Provider>;
};

const useAudio = () => {
  const [audioEndCallback, setAudioEndCallback] = useState<(() => void) | null>(null);

  const { audio, initialized } = React.useContext(AudioContext);

  const playAudio = async (src: string) => {
    if (!initialized) throw new Error("Audio not initialized");

    if (!audio.paused) audio.pause();

    audio.src = src;
    await audio.load();

    audio.volume = 1;
    audio.currentTime = 0;

    await audio.play();
  };

  const pauseAudio = (src?: string) => {
    if (src && audio.src !== src) return;
    if (audio.paused) return;

    audio.pause();
  };

  const setAudioVolume = (volume: number, src?: string) => {
    if (audio.src !== src) return;

    audio.volume = volume;
  };

  const onAudioEnd = (callback: () => void) => {
    setAudioEndCallback(() => callback);
  };

  useEffect(() => {
    if (!audioEndCallback) return () => {};

    audio.onended = audioEndCallback;

    return () => {
      audio.onended = null;
    };
  }, [audio, audioEndCallback]);

  return { audioIsInitialized: initialized, onAudioEnd, pauseAudio, playAudio, setAudioVolume };
};

export { AudioProvider, useAudio };
