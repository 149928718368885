import { useEffect, useState } from "react";
import { io } from "socket.io-client";

import { API_PROTOCOL, API_SERVER } from "./config";

/**
 * Provides a data stream from the API server.
 */
const useAPIStream = () => {
  const [data, setData] = useState<any>(null);
  const [socketID, setSocketID] = useState<string | null>(null);

  useEffect(() => {
    const socket = io(`${API_PROTOCOL}://${API_SERVER}`, { multiplex: false });

    socket.on("connect", () => {
      setSocketID(socket.id);
    });

    socket.on("message", setData);

    return () => {
      socket.disconnect();
    };
  }, []);

  return { stream: socketID, streamData: data };
};

export default useAPIStream;
