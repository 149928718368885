import { forwardRef, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import LessonContext from "components/Lesson/LessonContext";
import ExerciseContext from "components/Lesson/Exercise/ExerciseContext";
import twm from "utils/twm";

type CompleteExerciseButtonButtonProps = {
  className?: string;
  continueText: string;
  handleClick: () => void;
  isLastExercise: boolean;
};

export const CompleteExerciseButtonButton = forwardRef<HTMLButtonElement, CompleteExerciseButtonButtonProps>(
  ({ className, continueText, handleClick, isLastExercise }, ref) => {
    const { t } = useTranslation();

    return (
      <button
        className={twm(
          "text-white rounded-sm select-none text-center cursor-pointer outline-none transition-colors focus:outline-none",
          {
            "bg-blue-500 hover:bg-blue-600": isLastExercise,
            "bg-green-500 hover:bg-green-600": !isLastExercise,
          },
          className
        )}
        onClick={handleClick}
        ref={ref}
      >
        {isLastExercise ? t("lesson.exercise.concludeLesson") : continueText}
      </button>
    );
  }
);

type CompleteExerciseButtonProps = { className?: string; onClick?: () => void; text?: string };

const CompleteExerciseButton = forwardRef<HTMLButtonElement, CompleteExerciseButtonProps>(
  ({ className, onClick, text }, ref) => {
    const { exercisePointer, lesson } = useContext(LessonContext);
    const { onComplete, type } = useContext(ExerciseContext);
    const { t } = useTranslation();

    const continueText = useMemo(() => {
      if (text) return text;
      switch (type) {
        case "passive":
          return t("lesson.exercise.continue");
        default:
          return t("lesson.exercise.nextExercise");
      }
    }, [t, text, type]);

    const handleClick = useCallback(() => {
      if (onClick) onClick();
      else onComplete();
    }, [onClick, onComplete]);

    const isLastExercise = useMemo(() => exercisePointer === lesson.exercises.length - 1, [exercisePointer, lesson]);

    return (
      <CompleteExerciseButtonButton
        className={className}
        continueText={continueText}
        handleClick={handleClick}
        isLastExercise={isLastExercise}
        ref={ref}
      />
    );
  }
);

export default CompleteExerciseButton;
