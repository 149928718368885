import makeRequest from "services/api/makeRequest";
import { ServiceType } from "services/api/types";

type TranslateWordData = { from?: string; to: string; word: string };
type TranslateWord = ServiceType<TranslateWordData, any>;

export const translateWord: TranslateWord = async function translateWordService(context, data) {
  const options = { data, method: "POST" };

  return makeRequest(context, "/translate/word", options);
};
