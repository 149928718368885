import React from "react";

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  children?: React.ReactNode;
  maxHeight?: number;
  ref?: React.Ref<HTMLTextAreaElement>;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea: React.ForwardRefRenderFunction<HTMLTextAreaElement, TextareaProps> = (
  { children, maxHeight = 200, onChange, value, ...props },
  ref_
) => {
  const ref = React.useRef<HTMLTextAreaElement | null>(null);
  React.useImperativeHandle(ref_, () => ref.current as HTMLTextAreaElement);

  React.useEffect(() => {
    if (ref.current) {
      ref.current.style.height = "inherit";
      ref.current.style.height = `${Math.min(ref.current.scrollHeight, maxHeight)}px`;
    }
  }, [value]);

  return (
    <textarea
      {...props}
      ref={ref}
      onChange={(e) => {
        if (onChange) onChange(e);
        e.target.style.height = "inherit";
        e.target.style.height = `${Math.min(e.target.scrollHeight, maxHeight)}px`;
      }}
      value={value}
      cols={1}
      rows={1}
    >
      {children}
    </textarea>
  );
};

export default React.forwardRef(Textarea);
