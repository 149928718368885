import React from "react";
import { twMerge } from "tailwind-merge";

type ChatBubbleProps = {
  children: React.ReactNode;
  className?: string;
};

const ChatBubble = function ChatBubbleComponent({ children, className }: ChatBubbleProps) {
  return (
    <div className={twMerge("relative flex flex-col bg-slate-50 rounded-md py-2 px-3 w-[90%]", className)}>
      {children}
    </div>
  );
};

export default ChatBubble;
