import { useMemo, Fragment, useState, useRef, useCallback, useEffect } from "react";

import pretreatText, { Token } from "utils/pretreatText";
import SelectableWord from "./SelectableWord";
import classNames from "classnames";
import { SpeechSynthProps } from "components/useSpeechSynth";
import { useExercise } from "components/Lesson/Exercise";

import { twMerge } from "tailwind-merge";
import twm from "utils/twm";
import { useTranslation } from "react-i18next";

type BlankProps = { number: number; subNumber: number; hint?: string };
const Blank = function BlankComponent({ number, subNumber }: BlankProps) {
  const { type, data, state, pushState } = useExercise();
  const exerciseAnswer = data.answers?.[number - 1]?.[subNumber] ?? "";

  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState("");
  const [empty, setEmpty] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleClick = useCallback(() => {
    setEmpty(false);
    if (type !== "fill-in") return;
    setEdit(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  }, [type, inputRef]);
  const hint = data?.hints?.[number - 1];
  const extend: string = Array.from(
    { length: Math.max(data.answers?.[number - 1]?.[0]?.length ?? 0, value.length) - 3 },
    () => "_",
  ).join("");

  useEffect(() => {
    if (!state.preSubmit) return () => {};
    if (value.length === 0) {
      setEmpty(true);
      pushState((prev: { [key: string]: any }) => ({ ...prev, preSubmit: false }));
      return () => {};
    }
    return () => {};
  }, [state.preSubmit]);

  const correct = value?.toUpperCase().trim() === exerciseAnswer?.toUpperCase().trim();

  return (
    <span
      onClick={handleClick}
      className={twm(
        "inline-block z-10 relative bg-white leading-8 rounded border tracking-normal px-2",
        {
          "border-red-500 text-red-500": empty,
          "border-green-500 after:absolute after:z-0 after:content-[''] after:bg-green-500 after:inset-0 after:animate-pulse-once after:opacity-0 text-green-500":
            state.preSubmit && correct,
          "cursor-pointer": type === "fill-in"
        },
      )}
    >
      {state.preSubmit && value.length > 0 && !correct ? (
        <span className="text-green-500">&nbsp;{exerciseAnswer}&nbsp;</span>
      ) : (
        ""
      )}

      <span className={twm("relative", { "align-bottom -mt-1": edit || value, "text-red-500": empty })}>
        ______{(edit || value) && extend}
        {
          <input
            ref={inputRef}
            className={twm(
              "absolute outline-none align-baseline inset-0 placeholder:font-normal placeholder:text-sm bg-transparent pl-2 text-blank font-semibold",
              {
                "italic text-sm line-through text-red-500": state.preSubmit && value.length > 0 && !correct,
                "text-green-500 font-bold": state.preSubmit && value.length > 0 && correct,
                "text-red-500": empty,
                hidden: type !== "fill-in",
              },
            )}
            placeholder={t("lesson.exercise.chatBubble.fillIn.placeholder")}
            readOnly={state.preSubmit || type !== "fill-in"}
            onBlur={() => {
              setEdit(false);
            }}
            onFocus={() => {
              setEmpty(false);
            }}
            type="text"
            onChange={(e) => {
              setValue(e.target.value);
              pushState((prev: { [key: string]: any }) => ({
                ...prev,
                answers: {
                  ...prev?.answers,
                  [number - 1]: { ...prev?.answers?.[number - 1], [subNumber]: e.target.value },
                },
              }));
            }}
            defaultValue={value}
          />
        }
      </span>
      {hint && subNumber === 0 ? <span className="text-xs italic tracking-wide">&nbsp;({hint})</span> : ""}
    </span>
  );
};

const tokenToElement = (token: Token, showToken: boolean, disable: boolean = false) => {
  // explicit space for accurate line-breaking!
  if (token.isSpace) return <span className="tracking-widest select-none mx-px inline"> </span>;

  if (token.isBlank) return <Blank number={token.blankIndex} subNumber={token.subBlankIndex} />;

  if (token.isWord)
    return (
      <SelectableWord
        className={classNames({
          "opacity-20": !showToken,
          "opacity-100": showToken,
          "pointer-events-none": !showToken || disable,
          "pointer-events-auto": showToken && !disable,
        })}
        word={token.token}
        disable={!showToken || disable}
      />
    );

  // second-order groupings, word\s<punctuation>, replace whitespace with non-breaking space
  const display = /^\s$/gu.test(token.token[0]) ? <>&nbsp;{token.token.slice(1)}</> : <>{token.token}</>;

  return (
    <span
      className={classNames("select-none mx-px inline text-slate-600 pointer-events-none", {
        "opacity-20": !showToken,
        "opacity-100": showToken,
      })}
    >
      {display}
    </span>
  );
};

type SelectableTextProps = { className?: string; text: string; showUntilIndex?: number } & SpeechSynthProps;

// Displays selectable text including control over words
const SelectableText = function SelectableTextComponent({
  className,
  text,
  showUntilIndex = Number.MAX_VALUE,
}: SelectableTextProps) {
  const tokenGroups = useMemo(() => {
    try {
      return pretreatText(text);
    } catch (e) {
      return [];
    }
  }, [text]);

  if (!text) return null;

  return (
    <span id="line" className={twMerge(className, classNames("relative group select-none"))}>
      {tokenGroups?.map((tokenGroup, groupIndex) => {
        const groupLength = tokenGroup.length;
        let showGroup = true;

        if (groupLength === 1 && !tokenGroup[0].isWord)
          showGroup = tokenGroups
            .slice(groupIndex + 1)
            .some((group) => group.some((token) => token.isWord && token.wordIndex <= showUntilIndex));

        let showUntil =
          groupLength === 1 ? 0 : tokenGroup.findIndex((token) => token.isWord && token.wordIndex <= showUntilIndex);

        const lastWordIndex = tokenGroup.findLastIndex((token) => token.isWord);
        // no more words in the group
        showUntil = lastWordIndex === showUntil && showUntil < groupLength - 1 ? groupLength - 1 : showUntil;
        return (
          <Fragment key={groupIndex}>
            {groupLength > 1 ? (
              <span className="inline-block">
                {tokenGroup.map((token, tkIndex) => (
                  <Fragment key={tkIndex}>{tokenToElement(token, tkIndex <= showUntil)}</Fragment>
                ))}
              </span>
            ) : (
              tokenToElement(tokenGroup[0], tokenGroup[0].wordIndex <= showUntilIndex && showGroup)
            )}
          </Fragment>
        );
      })}
    </span>
  );
};

export default SelectableText;
