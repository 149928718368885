import classNames from "classnames";
import Loader from "components/Loader";
import React from "react";
import { twMerge } from "tailwind-merge";

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: React.EventHandler<any>;
  type?: "button" | "submit";
};

const Button: React.FC<ButtonProps> = function ButtonComponent({
  children,
  className,
  disabled,
  loading,
  onClick,
  type,
}) {
  return (
    <button
      className={twMerge(
        classNames(
          "flex justify-center items-center px-4 py-2 rounded-sm bg-slate-800 hover:bg-slate-900 disabled:bg-slate-800 transition-colors text-white font-semibold",
          {
            "cursor-default opacity-50": disabled,
          }
        ),
        className
      )}
      disabled={loading || disabled}
      onClick={onClick}
      type={type}
    >
      {loading ? <Loader className="w-[24px] h-[24px]" /> : children}
    </button>
  );
};

export default Button;
