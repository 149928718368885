import React, { useCallback, useMemo } from "react";

import { useFormErrors, UseFormErrorsProps } from "./FormErrors";

export type UseFormContextType = {
  dirty: boolean;
  formErrors: ReturnType<typeof useFormErrors>;
  hookSubmit: (cb: () => any) => React.EventHandler<any>;
  initialValues: any;
  resetInitialValues: () => void;
  submitting: boolean;
};
export type UseFormProps = UseFormErrorsProps;

type HookSubmitOptions = { fields?: string[] };

const useForm = ({ fields, validations }: UseFormProps) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(fields);

  const resetInitialValues = useCallback(
    (values?: Record<string, any>) => setInitialValues(values || fields),
    [fields, setInitialValues]
  );
  const dirty = useMemo(
    () => Object.keys(fields).some((key) => fields[key] !== initialValues[key]),
    [fields, initialValues]
  );

  const formErrors = useFormErrors({ fields, validations });

  const hookSubmit = useCallback(
    (cb: () => void, options: HookSubmitOptions = {}) =>
      async (event?: React.SyntheticEvent) => {
        if (submitting) return;

        setSubmitting(true);

        event?.preventDefault();
        event?.stopPropagation();

        formErrors.clearErrors();

        if (!formErrors.validate({ fields: options.fields, store: true })) return;

        let result: any;
        try {
          result = cb();
          if (result instanceof Promise) await result;
        } catch (error: any) {
          formErrors.addError(error);
        }

        setSubmitting(false);
      },
    [fields, formErrors, setSubmitting, submitting]
  );

  return { dirty, formErrors, hookSubmit, initialValues, resetInitialValues, submitting };
};

export default useForm;
