import { useCallback } from "react";

import { useAPI } from "services/api";
import { useExercise } from ".";
import DialogueExercise from "./shared/DialogueExercise";

const OpenQuestionExercise = function OpenQuestionExerciseComponent() {
  const { services } = useAPI();
  const exercise = useExercise();

  const evaluateService = useCallback(async (args: any) => services.evaluateOpenQuestion(args), [services]);
  const processMessage = useCallback((message: any) => message, []);

  return (
    <DialogueExercise
      defaultSpeakerID={exercise.data.speakerID}
      evaluateService={evaluateService}
      initialMessages={[{ content: exercise.data.text, role: "assistant" }]}
      processMessage={processMessage}
    />
  );
};

export default OpenQuestionExercise;
