import makeRequest from "services/api/makeRequest";
import { Lesson, ServiceType } from "services/api/types";

export type QueryLessonData = { lessonID: string };
export type QueryLessonResult = { lesson: any };
export type QueryLesson = ServiceType<QueryLessonData, QueryLessonResult>;

export const queryLesson: QueryLesson = async function queryLessonService(c, { lessonID }) {
  const options = { method: "GET" };

  return (await makeRequest(c, `/lesson/${lessonID}`, options)) as QueryLessonResult;
};

export type SearchLessonsData = { instruction: string; language: string; level: string; query: string };
export type SearchLessonsResult = { lessons: any[] };
export type SearchLessons = ServiceType<SearchLessonsData, SearchLessonsResult>;

export const searchLessons: SearchLessons = async function searchLessonsService(c, params) {
  const options = { params, method: "GET" };

  return makeRequest(c, `/lesson/search`, options);
};

export type SuggestLessonsData = { instruction: string; language: string; level: string };
export type SuggestLessonsResult = { lessons: any[]; shouldSignUp: boolean; vocab: boolean };
export type SuggestLessons = ServiceType<SuggestLessonsData, SuggestLessonsResult>;

export const suggestLessons: SuggestLessons = async function suggestLessonsService(c, params) {
  const options = { params, method: "GET" };

  return makeRequest(c, `/lesson/suggest`, options);
};

export type QueryLessonStatsData = { lessonID: string };
export type QueryLessonStatsResult = { exerciseCount: number; solvedCount: number };
export type QueryLessonStats = ServiceType<QueryLessonStatsData, QueryLessonStatsResult>;

export const queryLessonStats: QueryLessonStats = async function queryLessonStatsService(c, { lessonID }) {
  const options = { method: "GET" };

  return makeRequest(c, `/lesson/${lessonID}/stats`, options);
};

export type QueryLessonFeedbackData = { lessonID: string };
export type QueryLessonFeedbackResult = { lessonID: string; userID: string; rating: number; text: string };
export type QueryLessonFeedback = ServiceType<QueryLessonFeedbackData, QueryLessonFeedbackResult>;

export const queryLessonFeedback: QueryLessonFeedback = async function queryLessonFeedbackService(c, { lessonID }) {
  const options = { method: "GET" };

  return makeRequest(c, `/lesson/${lessonID}/feedback`, options);
};

export type ProvideLessonFeedbackData = { lessonID: string; rating: number; feedback: string };
export type ProvideLessonFeedback = ServiceType<ProvideLessonFeedbackData, object>;

export const provideLessonFeedback: ProvideLessonFeedback = async function provideLessonFeedbackService(
  c,
  { lessonID, ...data }
) {
  const options = { method: "POST", data };

  return makeRequest(c, `/lesson/${lessonID}/feedback`, options);
};

export type QueryCompletedLessonsData = { limit: number; offset: number };
export type QueryCompletedLessonsResult = { lessons: Lesson[] };
export type QueryCompletedLessons = ServiceType<QueryCompletedLessonsData, QueryCompletedLessonsResult>;

export const queryCompletedLessons: QueryCompletedLessons = async function queryCompletedLessonsService(c, params) {
  const options = { params, method: "GET" };

  return makeRequest(c, `/lesson/completed`, options);
};
