import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Medium from "components/Lesson/Medium";
import { useAPI } from "services/api";
import handleError from "utils/handleError";
import { useExercise } from ".";
import { CompleteExerciseButton, ScrollChat } from "./shared";
import ChatBubble from "./shared/ChatBubble";
import { useLesson } from "../LessonContext";
import { InputAndControls } from "./shared/InputAndControls";
import twm from "utils/twm";
import { AssistantBubble } from "./shared/DialogueExercise";

const OpenQuestionExercise = function OpenQuestionExerciseComponent() {
  const { services } = useAPI();
  const exercise = useExercise();
  const { onPreComplete, vocabularyView } = useLesson();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        await services.completeExercise({ exerciseID: exercise.id });
      } catch (error) {
        handleError(error);
      }

      onPreComplete();
    })();
  }, [exercise]);

  return (
    <>
      <ScrollChat className={twm({ hidden: vocabularyView })}>
        <div className="h-1"></div>
        {exercise.directions && (
          <AssistantBubble
            content={exercise.directions}
            header={t("lesson.exercise.directions")}
            className="bg-blue-100 w-full"
            disablePlay={true}
            plain={exercise.data?.plainDescription}
            disableTranslate={exercise.data?.plainDescription}
          ></AssistantBubble>
        )}

        <ChatBubble className="bg-slate-50 w-full">
          <Medium speechSynthID="medium" {...exercise.medium} />
        </ChatBubble>
        <div>&nbsp;</div>
      </ScrollChat>
      <InputAndControls>
        <CompleteExerciseButton />
      </InputAndControls>
    </>
  );
};

export default OpenQuestionExercise;
