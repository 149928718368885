import { useCallback, useEffect, useState } from "react";

import { useLesson } from "components/Lesson/LessonContext";
import useSpeechSynth, { SpeechSynthProps } from "components/useSpeechSynth";
import { loadSilence } from "data";
import { useAPI } from "services/api";
import { Timestamp } from "services/api/routes";
import { useAudio } from "utils/audio";
import PlainText from "./PlainText";
import SelectableText from "./SelectableText";

type ReadTextProps = {
  className?: string;
  text: string;
  displayAll?: boolean | undefined;
  plainText?: boolean;
  preload?: boolean;
} & SpeechSynthProps;
type SpeechSynth = { file: string; timestamps: Timestamp[] };

// Defines a wrapper for actively controlling speech synthesis
const ReadText = function ReadTextComponent({
  className,
  language,
  speakerID,
  speechSynthID,
  text,
  displayAll,
  plainText = false,
  preload = true,
}: ReadTextProps) {
  const [speechSynthPreload, setSpeechSynthPreload] = useState<SpeechSynth | null>(null);
  const [speechSynth, setSpeechSynth] = useState<SpeechSynth | null>(null);
  const [speechWordIndex, setSpeechWordIndex] = useState<number | null>(Number.MAX_VALUE);

  const { services } = useAPI();
  const { onAudioEnd, playAudio, pauseAudio, setAudioVolume } = useAudio();
  const { audio: isAudioEnabled } = useLesson();

  const loadSpeechSynth = useCallback(async () => {
    let response;
    try {
      const cleanText = text.replace(/____|___|=/gu, "");
      response = await services.speak({ language, speakerID, text: cleanText, timestamps: true });
    } catch (error) {
      return { file: loadSilence(), timestamps: [] };
    }

    return response;
  }, [language, services, speakerID, text]);

  const trigger = useCallback(async () => {
    setSpeechWordIndex(-1);
    setSpeechSynth(speechSynthPreload || (await loadSpeechSynth()));
  }, [loadSpeechSynth, speechSynthPreload]);

  const interrupt = useCallback(() => {
    setSpeechSynth(null);
    setSpeechWordIndex(null);
  }, [setSpeechSynth, setSpeechWordIndex, text]);

  const { onSpeechSynthComplete } = useSpeechSynth({ interrupt, speechSynthID, trigger });

  useEffect(() => {
    if (!preload) return () => {};

    (async () => {
      setSpeechSynthPreload(await loadSpeechSynth());
    })();

    return () => {
      setSpeechSynthPreload(null);
    };
  }, [preload]);

  useEffect(() => {
    if (!speechSynth) return;

    setAudioVolume(isAudioEnabled ? 1 : 0, speechSynth.file);
  }, [isAudioEnabled, speechSynth]);

  useEffect(() => {
    if (!speechSynth) return () => {};

    let timeouts: NodeJS.Timeout[];
    (async () => {
      try {
        await playAudio(speechSynth.file);
      } catch (error) {
        setSpeechSynth(null);
        return;
      }

      setAudioVolume(isAudioEnabled ? 1 : 0, speechSynth.file);
      onAudioEnd(() => setSpeechSynth(null));

      timeouts = speechSynth.timestamps.map(({ timestamp }: { timestamp: number }, index: number) =>
        setTimeout(() => setSpeechWordIndex(index), timestamp * 1000)
      );
    })();

    return () => {
      pauseAudio(speechSynth.file);
      timeouts?.forEach((timeout) => clearTimeout(timeout));
      onSpeechSynthComplete();
    };
  }, [speechSynth]);

  if (plainText)
    return (
      <PlainText
        text={text}
        className={className}
        showUntilIndex={speechWordIndex === null || displayAll ? Number.MAX_VALUE : speechWordIndex}
      />
    );
  return (
    <SelectableText
      text={text}
      className={className}
      showUntilIndex={speechWordIndex === null || displayAll ? Number.MAX_VALUE : speechWordIndex}
    />
  );
};

export default ReadText;
