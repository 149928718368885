import { useState } from "react";

const useDebounce = function useDebounce({ delay = 800 }: { delay?: number } = {}) {
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

  const debounce = (fn: (...args: any) => void) => {
    if (debounceTimeout !== null) clearTimeout(debounceTimeout);

    setDebounceTimeout(
      setTimeout(() => {
        fn();
        setDebounceTimeout(null);
      }, delay)
    );
  };

  const isDebouncing = debounceTimeout !== null;

  return { debounce, isDebouncing };
};

export default useDebounce;
