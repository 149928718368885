import { useTranslation } from "react-i18next";
// import { toast } from "react-toastify";

const emptyFn = (text: string) => text;

const useErrorHandler = () => {
  const { t } = useTranslation();

  const handleError = (error: any, defaultMessage?: string) => {
    if (!error || !error.isAPIError || !error.message) {
      emptyFn(t(defaultMessage ?? "error.unknown"));
      return;
    }

    emptyFn(t(error.message ?? "error.unknown"));
  };

  return handleError;
};

export default useErrorHandler;
