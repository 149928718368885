// import React, { useEffect, useMemo, useState } from "react";
// import { useTranslation } from "react-i18next";

// import HoverPopup from "components/HoverPopup";
// import Loader from "components/Loader";
// import { useAPI, useAPIStream } from "services/api";
// import { useCourseContext } from "components/UserInterface/CourseContext";
// import { ChevronDownIcon } from "@heroicons/react/24/outline";

interface ProofreadProps {
  className?: string;
  text: string;
}

// interface ProofreadType {
//   corrected: string;
//   explanations: string[];
//   mistakes: string[];
// }

// const ProofreadPopup: React.FC<ProofreadType> = ({ corrected, explanations }) => {
//   const { t } = useTranslation();

//   return (
//     <div className="text-left w-64 sm:w-96">
//       <div className="text-xs text-green-700 mb-2">{t("lesson.exercise.chatBubble.proofread.correctionTitle")}</div>
//       <div className="text-sm mb-2 text-green-700">{corrected}</div>
//       <div>
//         {explanations?.map((explanation, index) => (
//           <div key={index} className="text-xs">
//             {index + 1}. {explanation}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const Proofread: React.FC<ProofreadProps> = function ProofreadComponent({ className, text }) {
const Proofread: React.FC<ProofreadProps> = function ProofreadComponent() {
  return null;
  // const { services } = useAPI();
  // const { stream, streamData } = useAPIStream();
  // const { t } = useTranslation();
  // const [proofread, setProofread] = useState<ProofreadType | null>(null);

  // const { instruction, language } = useCourseContext();

  // useEffect(() => {
  //   if (!stream || !text) return;

  //   (async () => setProofread(await services.proofread({ instruction, language, sentence: text, stream })))();
  // }, [instruction, language, text, stream]);

  // useEffect(() => {
  //   setProofread(streamData);
  // }, [streamData]);

  // const hasMistake = useMemo(() => Boolean(proofread?.explanations?.length), [proofread, text]);

  // let content = <Loader className="h-4 w-4" />;
  // // TODO: This is a hack to prevent the content from showing up before the stream has completed.
  // if (proofread && (proofread.explanations?.length > 0 || "corrected" in proofread))
  //   content = hasMistake ? (
  //     <span className=" text-red-600 cursor-pointer align-middle flex items-center gap-2">
  //       <span>{t("lesson.exercise.chatBubble.proofread.incorrect")}</span>
  //       <ChevronDownIcon className="w-3 h-3 !stroke-[2.5] inline-block" />
  //     </span>
  //   ) : (
  //     <span className="text-green-600">{t("lesson.exercise.chatBubble.proofread.correct")}</span>
  //   );

  // return (
  //   <HoverPopup
  //     caret={false}
  //     className={className}
  //     popup={proofread && hasMistake && <ProofreadPopup {...proofread} />}
  //     popupMargin={20}
  //   >
  //     <span className="text-sm font-semibold">{content}</span>
  //   </HoverPopup>
  // );
};

export default Proofread;
