import React from "react";
import { useTranslation } from "react-i18next";

import { loadImage } from "data";
import { LanguageCode, languageCodeToFlag } from "utils/languages";
import twm from "utils/twm";

const loadImageFromCode = (code: LanguageCode) => loadImage(languageCodeToFlag(code));

type LanguageSelectOption = {
  disabled?: boolean;
  selected?: boolean;
  value: LanguageCode;
};

type LanguageSelectProps = {
  options: LanguageSelectOption[];
  onChange: (languageCode: LanguageCode) => void;
};

const LanguageSelect: React.FC<LanguageSelectProps> = function LanguageSelectComponent({ options, onChange }) {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-[28%,28%,28%] place-content-between gap-y-5 min-[450px]:gap-y-6 w-full max-w-md mx-auto">
      {options.map(({ disabled, selected, value }) => (
        <div
          className={twm(
            "rounded-md select-none mx-auto aspect-square w-[100px] h-[100px] md:w-[125.44px] md:h-[125.44px] border flex flex-col items-center justify-center space-y-2",
            {
              "cursor-pointer hover:bg-slate-50 border-blue-300 text-blue-500": selected,
              "cursor-text bg-slate-100 text-slate-500 border-slate-200 opacity-60": disabled,
              "cursor-pointer hover:bg-slate-50 border-slate-200": !selected && !disabled,
            },
          )}
          key={`${value}`}
          onClick={() => !disabled && onChange(value)}
        >
          <img src={loadImageFromCode(value)} alt="flag" className="w-[80%] shadow-sm" />
          <div className="w-[80%] text-xs min-[450px]:text-sm font-semibold">{t(`common.language.${value}`)}</div>
        </div>
      ))}
    </div>
  );
};

export default LanguageSelect;
