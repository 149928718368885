import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";

import UserInterface from "components/UserInterface";
import { loadImage } from "data";
import i18next from "utils/i18next";
import "./index.css";
// import reportWebVitals from "./reportWebVitals";
import Loader from "components/Loader";
import { APIProvider } from "services/api";
import { AudioProvider } from "utils/audio";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <div className="flex flex-col h-[100dvh]">
    <Suspense fallback={<Loader icon={<img src={loadImage("logo.svg")} alt="logo" />} className="animate-flip" />}>
      <I18nextProvider i18n={i18next}>
        <AudioProvider>
          <APIProvider>
            <UserInterface />
          </APIProvider>
        </AudioProvider>
      </I18nextProvider>
    </Suspense>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
