import { useCallback, useState } from "react";

import { Button, TextInput, useForm, Validations, FormErrors } from "components/form";
import { useAPI } from "services/api";
import { useTranslation } from "react-i18next";

const PasswordForm = function PasswordFormComponent({ onComplete }: { onComplete?: () => void }) {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordVerifier, setPasswordVerifier] = useState("");

  const { services } = useAPI();
  const { t } = useTranslation();

  const validations = [
    [["newPassword"], (v: string) => v.length >= 8, t("common.error.passwordTooShort")],
    [["newPassword", "passwordVerifier"], (p: string, pv: string) => p === pv, t("common.error.passwordsDontMatch")],
  ] as Validations;

  const { formErrors, hookSubmit, submitting } = useForm({
    fields: { password, newPassword, passwordVerifier },
    validations,
  });

  const handleReset = useCallback(() => {
    setPassword("");
    setNewPassword("");
    setPasswordVerifier("");

    if (onComplete) onComplete();
  }, [onComplete, setPassword, setNewPassword, setPasswordVerifier]);

  const handleSubmit = hookSubmit(
    useCallback(async () => {
      await services.updateUserPassword({ password, newPassword });

      setPassword("");
      setNewPassword("");

      if (onComplete) onComplete();
    }, [newPassword, password, services, setNewPassword, setPassword])
  );

  return (
    <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
      <TextInput
        autoComplete="current-password"
        errors={formErrors.getErrors("password")}
        label={t("common.inputLabel.currentPassword")}
        value={password}
        onChange={setPassword}
        type="password"
      />
      <TextInput
        autoComplete="new-password"
        errors={formErrors.getErrors("newPassword")}
        label={t("common.inputLabel.newPassword")}
        value={newPassword}
        onChange={setNewPassword}
        type="password"
      />
      <TextInput
        autoComplete="new-password"
        errors={formErrors.getErrors("passwordVerifier")}
        label={t("common.inputLabel.passwordVerifier")}
        value={passwordVerifier}
        onChange={setPasswordVerifier}
        type="password"
      />
      <div className="flex justify-center gap-4">
        <Button className="self-center" disabled={submitting} onClick={handleReset} type="button">
          {t("common.cancel")}
        </Button>
        <Button
          className="self-center"
          disabled={submitting || !formErrors.validate()}
          loading={submitting}
          type="submit"
        >
          {t("common.save")}
        </Button>
      </div>
      <FormErrors context={formErrors} exclude={["password", "newPassword", "passwordVerifier"]} />
    </form>
  );
};

export default PasswordForm;
