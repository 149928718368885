import React from "react";

type ImageProps = {
  content: { src: string };
};

const Image: React.FC<ImageProps> = function ImageComponent({ content: { src } }) {
  const alt = "Image";
  return (
    <div className="flex justify-center grow">
      <img className="max-h-64" src={src} alt={alt} />
    </div>
  );
};

export default Image;
