import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import moment from "moment";
import { initReactI18next } from "react-i18next";

const formatTimeDelta = (value: number) => {
  if (value < 60) return `${value} seconds`;
  if (value < 3600) return `${Math.round(value / 60)} minutes`;

  return `${Math.round(value / 3600)} hours`;
};

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      format: (value: any, format: string, lng: string) => {
        if (format === "timedelta") return formatTimeDelta(value);
        if (value instanceof Date) return moment(value).format(format);

        return value;
      },
    },
  });

export default i18next;
