import React from "react";

import { BoundServiceType } from "./types";

type APIUser = {
  createdAt: string;
  email?: string;
  id: string;
  name?: string;
  premium: boolean;
  updatedAt: string;
};

type APIContextType = {
  accessToken: string | null;
  initialized: boolean;
  reauth: () => Promise<boolean>;
  services: { [key: string]: BoundServiceType<any, any> };
  setAccessToken: (accessToken: string | null) => void;
  signout: () => void;
  user: APIUser | null;
};

const APIContext = React.createContext<APIContextType>({} as APIContextType);

export default APIContext;
export type { APIContextType, APIUser };
