import { useState } from "react";

import { Button, TextInput, useForm, FormErrors, Validation } from "components/form";
import { useAPI } from "services/api";
import { useTranslation } from "react-i18next";

type DeleteAccountFormProps = { onCancel?: () => void; onComplete?: () => void };

const DeleteAccountForm = function DeleteAccountFormComponent({ onCancel, onComplete }: DeleteAccountFormProps) {
  const [password, setPassword] = useState("");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { services, signout } = useAPI();
  const { t } = useTranslation();

  const validations = [
    [["password"], (v: string) => v.length >= 1, t("settings.error.passwordMissing")],
  ] as Validation[];

  const { formErrors, hookSubmit, submitting } = useForm({ fields: { password }, validations });

  const handleSubmit = hookSubmit(async () => {
    await services.deleteUser({ password });
    signout();

    if (onComplete) onComplete();
  });

  return (
    <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
      <TextInput
        autoComplete="current-password"
        errors={formErrors.getErrors("password")}
        label={t("common.inputLabel.password")}
        value={password}
        onChange={setPassword}
        type="password"
      />
      <div className="flex justify-center gap-4">
        <Button disabled={submitting} type="button" onClick={onCancel && onCancel}>
          {t("common.cancel")}
        </Button>
        <Button
          className="self-center bg-red-700 hover:bg-red-800"
          disabled={submitting || !formErrors.validate()}
          loading={submitting}
          type="submit"
        >
          {t("settings.deleteAccount")}
        </Button>
      </div>
      <FormErrors context={formErrors} />
    </form>
  );
};

export default DeleteAccountForm;
