import classNames from "classnames";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import useScrollbarSize from "react-scrollbar-size";
import { twMerge } from "tailwind-merge";

import Loader from "components/Loader";
import { useContainer } from "utils/container";

type PopupProps = {
  caret?: boolean;
  children: React.ReactNode;
  className?: string;
  popup: React.ReactNode | null;
  popupClassName?: string;
  disable?: boolean;
  popupMargin?: number;
};

/**
 * A component that displays a popup when the user hovers over it.
 * The popup is positioned automatically to be centered on the element and appears above or below it, depending on where
 * there is more space.
 */
const HoverPopup = function HoverPopupComponent({
  caret = true,
  children,
  className,
  disable = false,
  popup,
  popupClassName,
  popupMargin = 0,
}: PopupProps) {
  const [hover, setHover] = useState(false);
  const containerRef = useContainer();
  const popupRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  const { width: scrollbarWidth } = useScrollbarSize();

  const [offsets, setOffsets] = useState({
    left: Number.MAX_VALUE,
    right: Number.MAX_VALUE,
    top: Number.MAX_VALUE,
    bottom: Number.MAX_VALUE,
  });

  const computeOffsets = useCallback(() => {
    if (!hover) return;
    if (!containerRef?.current || !popupRef?.current || !ref?.current) return;

    const boundingRect = containerRef.current.getBoundingClientRect();
    const popupRect = popupRef.current.getBoundingClientRect();
    const rect = ref.current.getBoundingClientRect();

    const centerX = rect.left + rect.width / 2;
    const left = centerX - boundingRect.left - popupRect.width / 2;
    const right = boundingRect.right - centerX - popupRect.width / 2 - scrollbarWidth;

    const top = rect.top - (popupRect.height + 6 + boundingRect.top);
    const bottom = boundingRect.bottom - (rect.bottom + popupRect.height + 6);

    setOffsets({ left, right, top, bottom });
  }, [hover, containerRef, popupRef, ref]);

  useLayoutEffect(computeOffsets, [hover, containerRef, popupRef, ref]);
  useEffect(() => {
    if (!popupRef?.current) return () => {};

    const resizeObserver = new ResizeObserver(computeOffsets);

    resizeObserver.observe(popupRef.current);

    return () => resizeObserver.disconnect();
  }, [popupRef, computeOffsets]);

  const popupStyles = useMemo<object>(() => {
    let marginLeft;

    if (offsets.left < 0) marginLeft = `${Math.abs(offsets.left) + popupMargin}px`;
    else if (offsets.right < 0) marginLeft = `-${Math.abs(offsets.right - popupMargin)}px`;

    return { marginLeft };
  }, [offsets]);

  return (
    <span
      className={twMerge(
        classNames("relative inline-block select-none group/hover-popup", className, {
          "pointer-events-none": disable,
        })
      )}
      // onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        setHover((prev) => !prev);
      }}
      ref={ref}
    >
      {children}
      {hover && popup && (
        <>
          <span
            className={classNames(
              "absolute leading-[2] text-sm flex flex-col px-4 py-3 min-w-[200px] rounded-md bg-white z-20 left-1/2 transform -translate-x-1/2",
              {
                "-translate-y-full top-0 shadow-lg": offsets.bottom < 0,
                "shadow-lg": offsets.bottom >= 0,
              },
              popupClassName
            )}
            ref={popupRef}
            style={popupStyles}
          >
            <React.Suspense fallback={<Loader className="w-4 h-4" />}>{popup}</React.Suspense>
          </span>
          {caret && (
            <span
              className={classNames(
                "absolute w-3 h-3 bg-white transform z-30 rotate-45 -translate-x-1/2 left-[calc(50%)]",
                {
                  "-translate-y-full top-[6px]": offsets.bottom < 0,
                  "bottom-[-6px]": offsets.bottom >= 0,
                }
              )}
            />
          )}
        </>
      )}
    </span>
  );
};

export default HoverPopup;
