import React, { useCallback } from "react";

import APIContext, { APIContextType } from "./APIContext";
import * as routes from "./routes";
import { ServiceType } from "./types";

type Routes = { [key: string]: ServiceType<any, any> };

const useAPI = (): APIContextType => {
  const context = React.useContext<APIContextType>(APIContext);

  if (context === undefined) throw new Error("useAPI must be used within an APIProvider");

  const bindContext = useCallback(
    (services: { [key: string]: ServiceType<any, any> }) =>
      Object.fromEntries(
        Object.entries(services).map(([key, service]) => [key, (args: any) => service(context, args)])
      ),
    [context]
  );

  const augmentedContext = { ...context, services: bindContext(routes as Routes) } as APIContextType;

  return augmentedContext;
};

export default useAPI;
