import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Lesson from "components/Lesson";
import { useAudio } from "utils/audio";

let audioIsInitialized = false;

export const LessonPage: React.FC = function LessonPageComponent() {
  const { lessonID } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const audioContext = useAudio();

  useEffect(() => {
    // wait for audio context to update on click before navigating
    if (audioContext.audioIsInitialized) {
      audioIsInitialized = true;
      return;
    }

    setTimeout(() => {
      if (audioIsInitialized) return;

      navigate("/course");
    }, 150);
  }, [audioContext.audioIsInitialized]);

  if (!lessonID || !audioContext.audioIsInitialized) return null;

  return (
    <div className="flex flex-col flex-grow">
      <Lesson lessonID={lessonID} onExit={() => navigate("/")} {...state} />
    </div>
  );
};

export default LessonPage;
