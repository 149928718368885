import React from "react";
import { loadImage } from "data";
import { LanguageCode, languageCodeToFlag } from "utils/languages";
import { useTranslation } from "react-i18next";
import { useCourseContext } from "../CourseContext";

const loadImageFromCode = (code: LanguageCode | undefined) => code && loadImage(languageCodeToFlag(code));

const SetupOverview: React.FC = function SetupOverviewComponent() {
  const { language, level } = useCourseContext();
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center select-none">
      {/* <h1 className="mb-4 font-semibold font-sky-900">I speak</h1>
      <div className="shrink-0  text-slate-900">
        <div className="border px-3 grow py-2 rounded-md flex items-center gap-4  border-slate-200 bg-slate-100">
          <img src={loadImageFromCode(instruction as LanguageCode)} alt="flag" className="w-16 shadow-sm " />
          <div className="font-semibold text-xl">{t(`common.language.${instruction}`)}</div>
        </div>
      </div> */}
      <h1 className="mb-4 mt-16 font-semibold font-sky-900"></h1>
      <div className="shrink-0  text-slate-900">
        <div className="border px-3 grow py-2 rounded-md flex items-center gap-4  border-slate-200 bg-slate-100">
          <img src={loadImageFromCode(language as LanguageCode)} alt="flag" className="w-16 shadow-sm " />
          <div className="font-semibold text-xl">
            {t(`common.language.${language}`)} {level?.toUpperCase()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupOverview;
