import { Buffer } from "buffer";

/**
 * Decodes a JWT token and returns the payload.
 * @param token The JWT token to decode.
 * @returns The payload of the JWT token.
 */
const decodeJWT = function decodeJSONWebToken<T>(token: string): T {
  const tokenParts = token.split(".");

  if (tokenParts.length !== 3) throw new Error(`Invalid JSON Web Token: ${token}`);

  let payloadJSON;
  try {
    payloadJSON = Buffer.from(tokenParts[1], "base64").toString("utf8");
  } catch (e) {
    throw new Error(`Invalid JSON Web Token: ${token}`);
  }

  let payload: T;
  try {
    payload = JSON.parse(payloadJSON);
  } catch (e) {
    throw new Error(`Invalid JSON Web Token: ${token}`);
  }

  return payload;
};

export default decodeJWT;
