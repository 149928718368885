export type LanguageCode = "en" | "de" | "fr" | "es" | "it" | "pt";

const languageCodeNameMap: Record<LanguageCode, string> = {
  en: "english",
  de: "german",
  fr: "french",
  es: "spanish",
  it: "italian",
  pt: "portuguese",
};

const languageCodeFlagPathMap: Record<LanguageCode, string> = {
  en: "us",
  de: "de",
  fr: "fr",
  es: "es",
  it: "it",
  pt: "pt",
};

/**
 * Converts a language code to a language name.
 * @param languageCode The language code to convert.
 * @returns The language name for the given language code.
 */
export const languageCodeToName = (languageCode: LanguageCode): string => languageCodeNameMap[languageCode];

/**
 * Retrieves the path to the flag image for a given language code.
 * @param languageCode The language code to retrieve the flag image path for.
 * @returns The path to the flag image for the given language code.
 */
export const languageCodeToFlag = (languageCode: LanguageCode): string =>
  `flags/${languageCodeFlagPathMap[languageCode]}.svg`;
