import React, { forwardRef, useCallback, useImperativeHandle } from "react";

import { FormError } from "./FormErrors";
import InputLabel from "./InputLabel";

interface CheckBoxInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  errors?: FormError[];
  label?: string;
  onChange?: (_: boolean) => void;
}

const CheckBoxInput = forwardRef<HTMLInputElement, CheckBoxInputProps>(
  ({ errors = [], label, onChange, value, ...props }, ref) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(e.target.checked),
      [onChange]
    );

    return (
      <div className="flex flex-col grow">
        <div className="flex flex-row items-center py-2">
          <InputLabel className="grow" label={label} />
          <input
            className="h-5 w-5 hover:cursor-pointer text-sm"
            onChange={handleChange}
            ref={inputRef}
            type="checkbox"
            value={value}
            {...props}
          />
        </div>
        {errors.map((error, idx) => (
          <div className="flex text-red-600 text-xs py-1 px-2" key={idx}>
            {error.message}
          </div>
        ))}
      </div>
    );
  }
);

export default CheckBoxInput;
