import { useState } from "react";

import { Button, TextInput, useForm, Validations, FormErrors } from "components/form";
import { useAPI } from "services/api";
import { APIUser } from "services/api/APIContext";
import { useTranslation } from "react-i18next";

type UserFormProps = { onComplete?: () => void; user: APIUser };

const UserForm = function UserFormComponent({ onComplete, user }: UserFormProps) {
  const [name, setName] = useState(user.name || "");

  const { reauth, services } = useAPI();
  const { t } = useTranslation();

  const validations = [[["name"], (v: string) => v.length >= 2, t("common.error.nameTooShort")]] as Validations;

  const { dirty, formErrors, hookSubmit, initialValues, resetInitialValues, submitting } = useForm({
    fields: { name },
    validations,
  });

  const handleSubmit = hookSubmit(async () => {
    await services.updateUser({ name });
    await reauth();

    resetInitialValues();

    if (onComplete) onComplete();
  });

  return (
    <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
      <TextInput
        errors={formErrors.getErrors("name")}
        initialValue={initialValues.name}
        label={t("common.inputLabel.name")}
        value={name}
        onChange={setName}
      />

      <Button disabled={!dirty || submitting || !formErrors.validate()} loading={submitting} type="submit">
        {t("common.save")}
      </Button>
      <FormErrors context={formErrors} exclude={["name"]} />
    </form>
  );
};

export default UserForm;
