import makeRequest from "services/api/makeRequest";
import { ServiceType } from "services/api/types";

export type QueryMediumData = { mediumID: string };
export type QueryMedium = ServiceType<QueryMediumData, any>;

export const queryMedium: QueryMedium = async function queryMediumService(context, { mediumID }: QueryMediumData) {
  const options = { method: "GET" };

  return makeRequest(context, `/medium/${mediumID}`, options);
};
