import { XMarkIcon } from "@heroicons/react/24/solid";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { FormError } from "./FormErrors";
import InputLabel from "./InputLabel";

type TextInputProps = {
  autofocus?: boolean;
  className?: string;
  disabled?: boolean;
  errors?: FormError[];
  initialValue?: string;
  label?: string;
  onChange?: (value: string) => void;
  type?: string;
  value: string;
  [key: string]: any;
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      autofocus = false,
      className,
      disabled = false,
      errors = [],
      initialValue = "",
      label,
      onChange,
      type,
      value,
      ...props
    },
    ref,
  ) => {
    const [focused, setFocused] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    useEffect(() => {
      if (!autofocus) return;

      setFocused(true);
    }, [autofocus]);

    useEffect(() => {
      if (!focused) return;

      inputRef.current?.focus();
    }, [focused]);

    const expand = useMemo(() => focused || value, [focused, value]);

    const handleClear = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        if (onChange) onChange(value === initialValue ? "" : initialValue);

        setFocused(true);
      },
      [initialValue, onChange, value],
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (onChange) onChange(event.target.value);
      },
      [onChange],
    );

    return (
      <div className="flex flex-col grow">
        <div
          className={twMerge(
            classNames(
              "flex flex-col border-slate-300 px-4 py-[1px] border rounded h-16 justify-center items-stretch",
              {
                "border-2 border-blue-500 py-0": !disabled && focused,
                "border-red-500": Boolean(errors.length),
                "bg-slate-200": disabled,
              },
              className,
            ),
          )}
          onBlur={() => setFocused(false)}
          onClick={() => setFocused(true)}
          onFocus={() => setFocused(true)}
          tabIndex={disabled || focused ? -1 : 0}
        >
          <InputLabel
            className={twMerge(
              classNames("text-sm text-blue-500", {
                "cursor-text text-slate-500": !focused,
                "text-lg": !expand,
                "cursor-default text-slate-500": disabled,
              }),
            )}
            label={label}
            onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.preventDefault()}
          />
          <div className={classNames("flex items-center gap-2", { hidden: !expand })}>
            <input
              autoComplete="off"
              autoCorrect="off"
              className={twMerge(
                classNames("grow outline-none text-lg text-black bg-transparent", {
                  "text-slate-500": disabled,
                }),
              )}
              disabled={disabled}
              onChange={handleChange}
              ref={inputRef}
              type={type ?? "text"}
              value={value}
              {...props}
            />
            <button
              className={classNames("flex-shrink-0 text-slate-600 hover:text-black transition-colors", {
                hidden: (!initialValue && !value) || disabled,
              })}
              onClick={handleClear}
              onMouseDown={(e) => e.preventDefault()}
              tabIndex={-1}
              type="reset"
            >
              {!initialValue || value === initialValue ? (
                <XMarkIcon className="w-5 h-5" />
              ) : (
                <ArrowPathIcon className="w-5 h-5" />
              )}
            </button>
          </div>
        </div>
        {errors.map((error, idx) => (
          <div className="flex text-red-600 text-xs py-1 px-2" key={idx}>
            {error.message}
          </div>
        ))}
      </div>
    );
  },
);

export default TextInput;
