import { useCallback } from "react";

import { useAPI } from "services/api";
import { useLesson } from "components/Lesson/LessonContext";
import { useExercise } from ".";
import DialogueExercise from "./shared/DialogueExercise";

const ConversationExercise = function ConversationExerciseComponent() {
  const { services } = useAPI();
  const {
    data: { narratorID, scene, speakerID, text },
  } = useExercise();
  const {
    lesson: { level },
  } = useLesson();

  const evaluateService = useCallback(async (args: any) => services.evaluateConversation(args), [services]);
  const processMessage = useCallback(
    ({ className, ...message }: any) => ({
      className: className ?? (message.role === "assistant" ? "bg-blue-100" : "bg-green-100"),
      ...message,
    }),
    []
  );

  return (
    <DialogueExercise
      defaultSpeakerID={speakerID}
      evaluateService={evaluateService}
      initialMessages={[
        {
          className: "bg-slate-50",
          content: scene,
          role: "assistant",
          speakerID: narratorID,
          plain: ["a1", "a2"].includes(level),
        },
        { content: text, role: "assistant", speakerID },
      ]}
      processMessage={processMessage}
    />
  );
};

export default ConversationExercise;
