import { ReadText } from "components/Text";
import React from "react";

type TableProps = {
  content: { rows: string[][]; translations?: string[][] };
  speechSynthID: string;

  languageSpeakerID: string;
  disablePlay: boolean;
  className?: string;
  pointerEvents?: boolean;
  onPlayClick: (id: null | string) => void;
};

const Table: React.FC<TableProps> = function TableComponent({ content: { rows } }) {
  return (
    <div className="flex justify-start py-6 transform max-sm:[&_span]:leading-8 max-sm:[&_span]:!text-[10pt]">
      <table className="table shadow border border-slate-200">
        <thead>
          <tr className="table-row bg-slate-100">
            {rows[0].map((item: string, idx: number) => (
              <td key={idx} className="table-cell px-3 py-1 sm:px-4 sm:py-2 w-max whitespace-nowrap">
                {item === "00" ? <>&nbsp;</> : <ReadText className="" text={item.replace(/^plain_/gu, "")} displayAll={true} plainText={/^plain_/.test(item)} />}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.slice(1).map((row: string[], ridx: number) => (
            <tr key={ridx} className="table-row even:bg-slate-100">
              {row.map((item: string, idx: number) => {
                return (
                  <td key={idx} className="table-cell px-3 py-1 sm:px-4 sm:py-2 w-max whitespace-nowrap">
                    {item === "00" ? <>&nbsp;</> : <ReadText text={item.replace(/^plain_/gu, "")} displayAll={true} plainText={/^plain_/.test(item)}  />}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
